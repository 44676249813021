import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import HomepageLayout from 'layout/HomepageLayout';

// render - dashboard
const Homepage = Loadable(lazy(() => import('pages/index')));

// ==============================|| HOMEPAGE ROUTING ||============================== //
//homepage initializes & will navigate to /home if logged in

const HomeRoutes = {
    path: '/',
    element: <HomepageLayout />,
    children: [
        {
            path: '/',
            element: <Homepage />
        }
    ]
};

export default HomeRoutes;
