import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import { ProtectedRoute } from 'components/ProtectedRoute';

const Club = Loadable(lazy(() => import('pages/club')));
const Profile = Loadable(lazy(() => import('pages/profile')));
const Account = Loadable(lazy(() => import('pages/account')));
const FindMyTeam = Loadable(lazy(() => import('pages/findmyteam')));
const Team = Loadable(lazy(() => import('pages/team')));
const Availability = Loadable(lazy(() => import('pages/availabilityapp')));
const Messages = Loadable(lazy(() => import('pages/messages')));
const Surveys = Loadable(lazy(() => import('pages/surveys')));
const Positions = Loadable(lazy(() => import('pages/positionsapp')));
const Sessions = Loadable(lazy(() => import('pages/sessions')));
const Evaluations = Loadable(lazy(() => import('pages/evaluations')));
const GameVideo = Loadable(lazy(() => import('pages/game-video')));
const Dashboard = Loadable(lazy(() => import('pages/dashboard')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'home',
            element: <ProtectedRoute><Dashboard /></ProtectedRoute>
        },
        {
            path: 'profile',
            element: <ProtectedRoute><Profile /></ProtectedRoute>
        },
        {
            path: 'account',
            element: <ProtectedRoute><Account /></ProtectedRoute>
        },
        {
            path: 'availability',
            element: <ProtectedRoute><Availability /></ProtectedRoute>
        },
        {
            path: 'positions',
            element: <ProtectedRoute><Positions /></ProtectedRoute>
        },
        {
            path: 'club',
            element: <ProtectedRoute><Club /></ProtectedRoute>
        },
        {
            path: 'club/:clubId',
            element: <ProtectedRoute><Club /></ProtectedRoute>
        },
        {
            path: 'find-my-team',
            element: <ProtectedRoute><FindMyTeam /></ProtectedRoute>
        },
        {
            path: 'team',
            element: <ProtectedRoute><Team /></ProtectedRoute>
        },
        {
            path: 'team/:clubId/:teamId',
            element: <ProtectedRoute><Team /></ProtectedRoute>
        },
        {
            path: 'messages',
            element: <ProtectedRoute><Messages /></ProtectedRoute>
        },
        {
            path: 'surveys',
            element: <ProtectedRoute><Surveys /></ProtectedRoute>
        },
        {
            path: 'session-planner',
            element: <ProtectedRoute><Sessions /></ProtectedRoute>
        },
        {
            path: 'session-planner/:sessionId',
            element: <ProtectedRoute><Sessions /></ProtectedRoute>
        },
        {
            path: 'evaluations',
            element: <ProtectedRoute><Evaluations /></ProtectedRoute>
        },
        {
            path: 'evaluations/:groupId',
            element: <ProtectedRoute><Evaluations /></ProtectedRoute>
        },
        {
            path: 'evaluations/:groupId/:evaluationId',
            element: <ProtectedRoute><Evaluations /></ProtectedRoute>
        },
        {
            path: 'game-video',
            element: <ProtectedRoute name="GV"><GameVideo /></ProtectedRoute>
        },
        {
            path: 'game-video/:videoId',
            element: <ProtectedRoute name="GV2"><GameVideo /></ProtectedRoute>
        },
        {
            path: 'game-video/:videoId/spotlights',
            element: <ProtectedRoute name="GV3"><GameVideo /></ProtectedRoute>
        },
        {
            path: 'game-video/:videoId/spotlights/:spotlightId',
            element: <ProtectedRoute name="GV4"><GameVideo /></ProtectedRoute>
        }
    ]
};

export default MainRoutes;
