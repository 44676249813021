import { Box, Typography, IconButton } from '@mui/material';
import { Facebook, Instagram, Twitter } from '@mui/icons-material';

const FooterLinks = () => {
    return (
        <Box bgcolor="#595959" color="white" px={4} py={6}>
            <Typography variant="h6" align="center" gutterBottom>
                Follow Us
            </Typography>
            <Box display="flex" justifyContent="center" mb={4}>
                {/*<IconButton color="inherit" href="https://www.facebook.com/YourFacebookPage">
                    <Facebook />
                </IconButton>
                <IconButton color="inherit" href="https://www.instagram.com/YourInstagramPage">
                    <Instagram />
                </IconButton>*/}
                <IconButton color="inherit" target="_new" href="https://twitter.com/grootshero">
                    <Twitter />
                </IconButton>
            </Box>
            <Typography variant="body2" align="center">
                {/* 
                <Box component="span" pr={2}>
                    <a href="/blog.html" style={{ color: 'inherit', textDecoration: 'none' }}>Topics</a>
                </Box>
                |*/}
                <Box component="span" px={2}>
                    <a href="/terms" style={{ color: 'inherit', textDecoration: 'none' }}>Terms</a>
                </Box>
                |
                <Box component="span" px={2}>
                    <a href="/privacy" style={{ color: 'inherit', textDecoration: 'none' }}>Privacy</a>
                </Box>
                |
                <Box component="span" px={2}>
                    &copy; {new Date().getFullYear()} Grassroots Hero LLC
                </Box>
            </Typography>
            <Typography variant="body2" align="center" mt={2}>
                
            </Typography>
        </Box>
    );
};

export default FooterLinks;
