
const Logo = () => {
    return (
        <img src="/static/logo-hero.webp" alt="Grassroots Hero" style={{
            boxSizing: "content-box",
            width: "120px"
        }} />
    );
};

export default Logo;
