import { LoginOutlined, ProfileOutlined } from '@ant-design/icons';
import { hasFeature } from "features";

// icons
const icons = {
    LoginOutlined,
    ProfileOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const coaches = {
    id: 'coaches',
    title: 'Coaches Corner',
    type: 'group',
    authOnly: true,
    children: []
};

if (hasFeature("session_plan")) {
    coaches.children.push({
        id: 'sessions1',
        title: 'Session Planner',
        type: 'item',
        url: '/session-planner',
        icon: icons.LoginOutlined,
        target: false
    });
}

if (hasFeature("evaluations")) {
    coaches.children.push({
        id: 'evaluations1',
        title: 'Evaluations',
        type: 'item',
        url: '/evaluations',
        icon: icons.LoginOutlined,
        target: false
    });
}

if (hasFeature("game_video")) {
    coaches.children.push({
        id: 'gamevideo1',
        title: 'Game Video',
        type: 'item',
        url: '/game-video',
        icon: icons.LoginOutlined,
        target: false
    });
}

export default coaches;
