import { createTheme } from '@mui/material/styles';

export const themeHero = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
      darker: '#666666',
    }
  },
});

export const themeModal = createTheme({
    palette: {
      primary: {
        main: '#333333',
        darker: '#000000',
      }
    },
});

export const modalStyle = {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 880,
    minHeight: 500,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    overflow: "auto",
    p: 4,
};
