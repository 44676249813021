import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// project import
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';
import componentsOverride from './overrides';

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({ children }) {

    const themeMode = useSelector((state) => {
        return state.appShell.themeMode;
    });

    const themes = {};
    themes['light'] = Palette('light', 'default');
    themes['dark'] = Palette('dark', 'default');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const themeTypography = Typography(`'Public Sans', sans-serif`);
    const themeShadows = {};
    themeShadows["light"] = useMemo(() => CustomShadows(themes["light"]), [themes["light"]]);
    themeShadows["dark"] = useMemo(() => CustomShadows(themes["dark"]), [themes["dark"]]);

    const themeOptions = useMemo(
        () => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    xl: 1536
                }
            },
            direction: 'ltr',
            mixins: {
                toolbar: {
                    minHeight: 60,
                    paddingTop: 8,
                    paddingBottom: 8
                }
            },
            palette: {
                mode: themeMode,
                ...themes[themeMode].palette
            },
            customShadows: themeShadows[themeMode],
            typography: themeTypography
        }),
        [themes[themeMode], themeTypography, themeMode]
    );

    const themeInstance = createTheme(themeOptions);
    themeInstance.components = componentsOverride(themeInstance);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themeInstance}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

ThemeCustomization.propTypes = {
    children: PropTypes.node
};
