

const RolesUtil = {

    addRoles: (obj, uid, roles) => {
        if (!obj.roles) {
            obj.roles = {};
        }
        obj.roles[uid] = obj.roles[uid] || [];

        roles.forEach(roleName => {
            if (!obj.roles[uid].includes(roleName)) {
                obj.roles[uid].push(roleName);
            }
        });
    },

    hasRole: (obj, uid, role) => {
        return (obj.roles[uid] && obj.roles[uid].includes(role));
    },

    removeRole: (obj, uid, role) => {
        const userRoles = obj.roles[uid];
        if (userRoles) {
            const roleIndex = userRoles.indexOf(role);
            if (roleIndex >= 0) {
                userRoles.splice(roleIndex, 1);
            }
            if (userRoles.length === 0) {
                delete obj.roles[uid];
            }
        }
    }
};

export default RolesUtil;