
import Diversity1SharpIcon from '@mui/icons-material/Diversity1Sharp';
import DateRangeSharpIcon from '@mui/icons-material/DateRangeSharp';
import EvStationSharpIcon from '@mui/icons-material/EvStationSharp';
import SettingsInputAntennaSharpIcon from '@mui/icons-material/SettingsInputAntennaSharp';

import { useTheme } from '@mui/material/styles';
import { useMediaQuery, IconButton, Button, Box, Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';

// ==============================|| FIREBASE - SOCIAL BUTTON ||============================== //

const MobileNavbar = ({ }) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    //useState selectionModel

    return (
        <Box sx={{
                border: "1px solid red",
                width: "100%",
                maxHeight: "49px"
            }}>
            <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent={'space-between'}
                sx={{
                    width: "100%",
                    height: "49px",
                    padding: "0px 33px",
                    fontSize: "2.5em",
                    color: "#666666" }}
            >
                <NavLink to='/availability'>
                    <DateRangeSharpIcon 
                        sx={{ fontSize: "1em" }} />
                </NavLink>
                <Diversity1SharpIcon 
                    sx={{ fontSize: "1em" }} />
                <SettingsInputAntennaSharpIcon
                    sx={{ fontSize: "1em" }} />
                <EvStationSharpIcon
                    sx={{ fontSize: "1em" }} />
            </Stack>
        </Box>
    );
};

export default MobileNavbar;
