import { React, useState } from "react";
import { Box, Stack, Rating, Button, TextField, Typography, ThemeProvider, Modal } from "@mui/material";

import { themeModal, modalStyle } from "common-theme";

const FeedbackForm = ({ onComplete }) => {
    const [step, setStep] = useState(1);
    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleNext = () => {
        if (step === 1) {
            setStep(2);
        } else {
            setStep(3);
            setIsSubmitted(true);
            setTimeout(() => {
                onComplete();
                setIsSubmitted(false);
            }, 2000);
        }
    };

    return (
        <ThemeProvider theme={themeModal}>
            <Modal open={true} onClose={onComplete} className="modal-shell">
                <Box display="flex" justifyContent="center" alignItems="center" sx={modalStyle}>
                    <Stack direction="column" spacing={1}>
                        {step === 1 && (
                            <>
                                <Typography>How you like me now?</Typography>
                                <Rating
                                    name="simple-controlled"
                                    value={rating}
                                    onChange={(event, newValue) => {
                                        setRating(newValue);
                                    }}
                                />
                            </>
                        )}
                        {step === 2 && (
                            <>
                            <Typography>Why did you rate us {rating} stars?</Typography>
                            <TextField value={feedback} multiline onChange={(e) => setFeedback(e.target.value)} />
                            </>)
                        }

                        {step === 3 && (
                            <Typography
                                sx={{
                                    opacity: isSubmitted ? 1 : 0,
                                    transition: "opacity 2s"
                                }}>
                                Thank you!
                            </Typography>
                        )}

                        <Button sx={{
                            display: (isSubmitted) ? "none" : "block" 
                        }} variant="outlined" onClick={handleNext}>{step === 1 ? "Next" : "Submit"}</Button>
                    </Stack>
                </Box>
            </Modal>
        </ThemeProvider>
    );
};

export default FeedbackForm;
