/**
 * 
 */
import { combineReducers } from 'redux';
import menu from './menu';
import appShell from './appShell';

const reducers = combineReducers({ menu, appShell });

export default reducers;
