// Import the functions you need from the SDKs you need

import Document from "./model/Document";
import DocumentSet from "./model/DocumentSet";
import RolesUtil from "./utils/roles";

const firebaseConfig = {
  apiKey: "AIzaSyCK1X9sXxNfOTl5ZIUcdOEjIs_AOcnnq9Y",
  authDomain: "grassroots-hero.firebaseapp.com",
  projectId: "grassroots-hero",
  storageBucket: "grassroots-hero.appspot.com",
  messagingSenderId: "988766529091",
  appId: "1:988766529091:web:3fb151a9c457253f0656f6",
  measurementId: "G-M4MFE6ZJJ7"
};

/*
connectFunctionsEmulator(_functions, 'localhost', '5001');
export const firebaseFunction = (name) => {
    return httpsCallable(_functions, name);
};

const _providerByName = (providerName) => {
    let provider = _providers[providerName];

    if (provider) {
        return provider;
    }

    if (providerName === "google") {
        provider = new GoogleAuthProvider();
        //scopes?

    } else if (providerName === "facebook") {
        provider = new FacebookAuthProvider();
        //scopes?

    } else if (providerName === "apple") {
        provider = new OAuthProvider("apple.com");
        provider.addScope('email');
        provider.addScope('name');
    } else {
        console.warn("failed to match provider: " + providerName);
    }
    _providers[providerName] = provider;
    return provider;
};
*/

class FireHero {

    constructor () {
        this.firestoreRefs = {};

        this._listening = false;
        this._hasAuthState = false;
        this._listeners = [];
        this._user = undefined;        
        this._providers = {};
        this._storePromises = {};
        this._docSets = {};
        this._objects = {};
        this._objectPromises = {};

        this._initPromise = this.init();
    }

    async initialized() {
        return this._initPromise;
    }

    async init() {
        const { initializeApp } = await import('firebase/app');
        const { getAnalytics } = await import('firebase/analytics');
        const { getFirestore, doc, getDoc, setDoc, updateDoc, collection, query, where, getDocs, addDoc } = await import('firebase/firestore');
        const { getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider } = await import('firebase/auth');

        //import { getAuth, onAuthStateChanged, signInWithPopup, signInWithRedirect, signInWithCredential, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider, getRedirectResult } from "firebase/auth";

        const { getFunctions } = await import('firebase/functions');

        this.firebaseApp = initializeApp(firebaseConfig);
        this.analytics = getAnalytics(this.firebaseApp);
        this.firebaseAuth = getAuth(this.firebaseApp);
        this.firestoreDB = getFirestore(this.firebaseApp);
        this._functions = getFunctions();

        //this.analytics.setUserProperties({app_version: '0.1.3'});

        this.firestoreRefs.onAuthStateChanged = onAuthStateChanged;
        this.firestoreRefs.signInWithPopup = signInWithPopup;
        this.firestoreRefs.GoogleAuthProvider = GoogleAuthProvider;
        this.firestoreRefs.doc = doc;
        this.firestoreRefs.firestoreDB = this.firestoreDB;
        this.firestoreRefs.getDoc = getDoc;
        this.firestoreRefs.setDoc = setDoc;
        this.firestoreRefs.updateDoc = updateDoc;
        this.firestoreRefs.collection = collection;
        this.firestoreRefs.query = query;
        this.firestoreRefs.where = where;
        this.firestoreRefs.getDocs = getDocs;
        this.firestoreRefs.addDoc = addDoc;
    }

    async checkUserAuth (cb) {
        await this.initialized();
        this._listeners.push(cb);
        if (!this._listening) {
            this._listening = true;
            this.firestoreRefs.onAuthStateChanged(this.firebaseAuth, (user) => {
                this._user = user;
                this._hasAuthState = true;
                for (var i=0;i<this._listeners.length;i++) {
                    this._listeners[i](user);
                }
            });
        } else if (this._hasAuthState) {
            cb(this._user);
        }
    }


    async authWithToken (token, providerName, cb) {
        await this.initialized();
        //console.log("providername = " + providerName);
    
        let credential;
        if (providerName === "google") {
            credential = GoogleAuthProvider.credential(token);
        } else {
            console.log("TBD provider " + providerName);
        }
        
        try {
            signInWithCredential(firebaseAuth, credential).then((userCredential) => {
                
                console.log("signed in!", userCredential);
    
                // Sign-in successful.
                hero.user = userCredential.user;
                cb(hero.user);
            }).catch((error) => {
                // Handle errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error("Failed with error", {errorCode, errorMessage});
                cb();
            });
        } catch (exx) {
            console.warn("Exx is " + exx, exx);
        }
    }

    async signIn () {
        await this.initialized();

        const provider = new this.firestoreRefs.GoogleAuthProvider();
        //signInWithRedirect(firebaseAuth, provider);
        
        this.firestoreRefs.signInWithPopup(this.firebaseAuth, provider).then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = provider.credentialFromResult(result);
            const token = credential.accessToken;
    
            console.log("got fresh token:" + token);
            // The signed-in user info.
            //const user = result.user;
            console.warn("AUTHEEE ", result);
            //
    
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            
            console.warn("FAILURE: ", {
                errorCode,
                errorMessage,
                error
            });
        });
        
    }

    async saveEmailToFirestore (email) {
        await this.initialized();

        const emailAddress = email.value;
        //const docRef = doc(firestoreDB, "notify_email_list", emailAddress);
        const docRef = this.firestoreRefs.doc(this.firestoreDB, "notify_email_list", emailAddress);
    
        await setDoc(docRef, {
            email: emailAddress
        })
        console.log("Email address written with ID: ", docRef.id);
    };

    //DOC STUFFS
    
    async loadOrCreateDoc (docPath, cbNewDoc, cbDoc) {
        await this.initialized();
        const docRef = this.firestoreRefs.doc(this.firestoreDB, docPath);
        if (!this._storePromises[docPath]) {
            this._storePromises[docPath] = this.firestoreRefs.getDoc(docRef);
        }
        const docSync = await this._storePromises[docPath];
        
        if (docSync.exists()) {
            cbDoc(new Document(docSync.data(), this.firestoreRefs, docRef));
        }
        else {
            const docData = cbNewDoc();
            await this.firestoreRefs.setDoc(docRef, docData);
            cbDoc(new Document(docData, this.firestoreRefs, docRef));
        }
    }

    async loadDocSet (containerName, cb) {
        await this.initialized();
        if (this._docSets[containerName]) {
            cb(this._docSets[containerName]);
            return this._docSets[containerName];
        }
        if (!this._storePromises[containerName]) {
            const docSetRef = this.firestoreRefs.collection(this.firestoreDB, containerName);
            this._storePromises[containerName] = this.firestoreRefs.getDocs(docSetRef).then((resultDocs) => {
                const docSet = new DocumentSet(docSetRef, this.firestoreRefs);
                resultDocs.forEach((doc) => {
                    docSet.addDocument(new Document(doc.data(), this.firestoreRefs, doc.ref));
                });
                this._docSets[containerName] = docSet;
                return docSet;
            });
        }
        const docSet = await this._storePromises[containerName];
        cb(docSet);
        return docSet;
    }

    //init empty doc set... uniformity for docs where we query the set dynamically
    async getDocSet (containerName) {
        await this.initialized();
        if (this._docSets[containerName]) {
            return this._docSets[containerName];
        }
        const docSetRef = this.firestoreRefs.collection(this.firestoreDB, containerName);
        const docSet = new DocumentSet(docSetRef, this.firestoreRefs);
        this._docSets[containerName] = docSet;
        return docSet;
    }

    //owned docs
    async queryDocSet (containerName) {
        await this.initialized();
        const docSet = await this.getDocSet(containerName);

        if (!this._storePromises[containerName]) {
            const setRef = this.firestoreRefs.collection(this.firestoreDB, containerName);        
            const q = this.firestoreRefs.query(setRef, this.firestoreRefs.where("roles." + this._user.uid, "array-contains", "member"));
        
            this._storePromises[containerName] = this.firestoreRefs.getDocs(q).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    docSet.addDocument(new Document(doc.data(), this.firestoreRefs, doc.ref));
                });
                return docSet;
            });
        }
        return this._storePromises[containerName];
    }

    //relational queries
    async loadRelatedDocs (sourceDocId, collectionName, fieldName, cbCreate, cb) {
        await this.initialized();
        const collectionRef = this.firestoreRefs.collection(this.firestoreDB, collectionName);
        
        console.log("field name " + fieldName);
        
        const q = this.firestoreRefs.query(collectionRef, this.firestoreRefs.where(fieldName, "==", sourceDocId));
        const querySnapshot = await this.firestoreRefs.getDocs(q);
        const resultSet = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " BOOM() => ", doc.data());
    
            //make an object from this doc and put it in the array
            const docObj = new Document(doc.data(), this.firestoreRefs, doc.ref);
            resultSet.push(cbCreate(docObj));
        });
        console.log("queried and completed");
    
        cb(resultSet);
        return resultSet;
    };

    //owned objects derived from docs
    async getObjects (collectionName, fnFactory) {
        if (!this._objects[collectionName]) {
            this._objects[collectionName] = [];
        }
        if (!this._objectPromises[collectionName]) {
            this._objectPromises[collectionName] = this.queryDocSet(collectionName).then((loadedDocSet) => {
                //create the objects
                for (const docItem of loadedDocSet.documents) {
                    const objectItem = fnFactory(docItem, loadedDocSet);
                    this._objects[collectionName].push(objectItem);
                }
                return this._objects[collectionName];
            });
        }
        await this._objectPromises[collectionName];
        return this._objects[collectionName];
    }

    async createObject (collectionName, objectData, roles, fnFactory) {
        const docSet = await this.getDocSet(collectionName);
        RolesUtil.addRoles(objectData, this._user.uid, roles);
        
        const newDoc = await docSet.createDocument(objectData);
        const newObj = fnFactory(newDoc, docSet);
        if (!this._objects[collectionName]) {
            this._objects[collectionName] = [];
        }
        this._objects[collectionName].push(newObj);
        return newObj;
    }

}

export const FBHERO = new FireHero();