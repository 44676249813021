class GameEvent  {

    static TYPE = "ev";

    constructor(keyframeData) {
        if (!keyframeData) {
            this.isNew = true;
            this._data = {
                a: GameEvent.TYPE,
                act: '',
                t: 0
            };
        } else {
            this._data = keyframeData;
        }
    }

    get isGameEvent() {
        return true;
    }

    saveAsData() {
        return this._data;
    }

    get time() {
        return this._data.t;
    }

    set time(sec) {
        this._data.t = sec;
    }

    get actionName() {
        return this._data.act;
    }

    set actionName(name) {
        this._data.act = name;
    }
}

export default GameEvent;