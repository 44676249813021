import { Box, Typography } from '@mui/material';

import { hero } from "hero"; 
import { useSelector } from 'react-redux';

import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {

    const appState = useSelector((state) => {
        return {
            loggedIn: state.appShell.loggedIn,
            isInitialized: state.appShell.isInitialized
        };
    });

    const navGroups = menuItem.items.map((item) => {

        if (item.authOnly && !(appState.isInitialized && appState.loggedIn)) {
            return;
        }
        if (item.unauthOnly && (!appState.isInitialized || appState.loggedIn)) {
            return;
        }

        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
