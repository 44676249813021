/**
 * 
 *  Zoom/Pan keyframe, represented by keyframe.a == "zp"
 * 
 */
class ReframeKeyframe  {

    static TYPE = "zp";

    constructor(keyframeData) {
        if (!keyframeData) {
            this.isNew = true;
        }
        this._data = keyframeData || {
            a: ReframeKeyframe.TYPE,
            x: 0.5,
            y: 0.5,
            z: 1.0,
            t: 0
        };
    }

    get isReframe() {
        return true;
    }

    saveAsData() {
        return this._data;
    }

    //time ms
    get time() {
        return this._data.t;
    }
    set time(tm) {
        this._data.t = tm;
    }

    //duration ms
    get duration() {
        return this._data.d || 0;
    }
    set duration(dur) {
        this._data.d = dur;
    }

    //zoom (1.0 == 100%)
    get zoomLevel() {
        return this._data.z;
    }
    set zoomLevel(lvl) {
        this._data.z = lvl;
    }

    //focus point measured in % position in the original video frame
    get focusPoint() {
        return { x: this._data.x, y: this._data.y}
    }

    set focusPoint(fp) {
        this._data.x = fp.x;
        this._data.y = fp.y;
    }

}

export default ReframeKeyframe;