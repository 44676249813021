// HeaderContext.js
import { createContext, useContext, useState } from 'react';

const HeaderContext = createContext();

export const useHeader = () => {
  return useContext(HeaderContext);
};

export const HeaderProvider = ({ children }) => {
  const [headerContent, setHeaderContent] = useState('');
  const [bgImage, setBgImage] = useState('');

  return (
    <HeaderContext.Provider value={{
      headerContent,
      setHeaderContent,
      bgImage,
      setBgImage
      }}>
      {children}
    </HeaderContext.Provider>
  );
};
