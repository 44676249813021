import { makeGUID } from "utils/uid";

class SpotlightEvent  {

    static KIND_REFERENCE = "ref";
    static KIND_PAUSE = "pau";
    static KIND_SPEED_MOD = "spd";

    static KIND_PLAYER_SPOTLIGHT = "psl";
    static KIND_TACTICAL = "tct";
    static KIND_TEXT = "txt";
    static KIND_TIME_CONTROL = "tme"
    
    constructor(dataIn) {
        this._data = dataIn || {
            id: makeGUID(),
            k: "ref",
            t: 0,
            o: 0,
            d: 3.0,
            x: 0.5,
            y: 0.5
        };
    }

    saveData() {
        return this._data;
    }

    get id() {
        return this._data.id;
    }

    set kind(knd) {
        this._data.k = knd;
    }

    get kind() {
        return this._data.k;
    }

    get focusPoint() {
        return { x: this._data.x, y: this._data.y };
    }

    set focusPoint(fp) {
        this._data.x = fp.x;
        this._data.y = fp.y;
    }

    get offset() {
        return this._data.o;
    }

    set offset(val) {
        this._data.o = val;
    }

    get timecode() {
        return this._data.t;
    }

    set timecode(val) {
        this._data.t = val;
    }

    get offsetEnd() {
        return this.offset + this.duration;
    }

    get timeEnd() {
        return this.timecode + this.duration;
    }

    get duration() {
        
        //this can be a calculation

        return this._data.d;
    }

    set duration(val) {
        this._data.d = val;
    }

    /**
     *
     * (k)ind:
     * "ref" (video reference)
     * "rev" (video reverse)
     * "spd" (sloMo/fastMo speed)
     * "pau" (pause)
     * 
    **/

    get isFootageRef() {
        return (this._data.k === "ref" || this._data.k === "rev" || this._data.k === "spd" || this._data.k === "pau");
    }




    //get timeControlAction {}
    //set timeControlAction(act) {}






    //might be: 

    //get actions() {}

    /**
     * actions: {
     *   playSpeed: -1.0,
     *   markResume: true
     * }
     * 
     * 
     * then later
     * actions: {
     *   playSpeed: 1.0,
     *   resume: true
     * }
     * 
     */

    //content
    get comments() {
        return "";
    }

    get players() {
        return [];
    }


    //key qualities?

    //player actions?
    
}

export default SpotlightEvent;