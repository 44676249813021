/**
 * EvaluationGroups can be for one or more Players
 * 
 */

import ModelBase from "./ModelBase";

class EvaluationGroup extends ModelBase {

    constructor(evaluationGroupDoc, hero) {
        super(evaluationGroupDoc);
        this._hero = hero;
        this._evaluations = undefined;
    }

    get name() {
        return this.getPropertyValue("name");
    }

    set name(newName) {
        this.setPropertyValue("name", newName);
    }

    get ageGroup() {
        return this.getPropertyValue("ageGroup");
    }

    set ageGroup(newAge) {
        this.setPropertyValue("ageGroup", newAge);
    }

    addEvaluationLocal(evaluation) {
        this._evaluations.push(evaluation);
    }

    getEvaluations(cb) {
        if (!this._evaluations) {
            this._hero.getEvaluationsForGroup(this, (evalList) => {
                this._evaluations = evalList;
                cb(this._evaluations);
            });
        } else {
            cb(this._evaluations);
        }
        
    }

    async create(docSet, userAdmin) {
        this._updates.roles = {};
        this._updates.roles[userAdmin.uid] = ["owner", "member"];
        
        this._doc = await docSet.createDocument(this._updates);
        this._updates = {};
        return this;
    }

    async save() {
        
        console.log("ABOUT TO SAVE:", this._updates);

        this.saveUpdates();
    }
}

export default EvaluationGroup;