import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

export const ProtectedRoute = ({ children }) => {
    const appState = useSelector((state) => {
        return {
            loggedIn: state.appShell.loggedIn,
            isInitialized: state.appShell.isInitialized
        };
    });
    if (!appState.isInitialized) {
        return (<Box>
            <Typography>Loading...</Typography>
        </Box>);
    }
    if (!appState.loggedIn) {
        return <Navigate to="/login" />;
    }
    return children;
};