

const features = {
    coaches_corner: true,
    evaluations: true,
    game_video: true,
    session_plan: true,
    game_plan: false,
    clubhouse: true,
    clubs_and_teams: false,
    managers_desk: true,
    dark_mode: false,
    notifications: false,
    home_learn_more: false,
    home_login: false
};

export const hasFeature = (name) => {
    return (!!features[name]);
};

