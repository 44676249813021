class ModelBase  {

    constructor(doc) {
        this._doc = doc;
        this._updates = {};
    }
    
    get id() {
        if (this._doc) {
            return this._doc.id;
        }
        return "new";
    }

    async saveUpdates() {
        const updatePayload = {};
        for (let key in this._updates) {
            if (this._updates.hasOwnProperty(key)) {
                this._doc.updateDocFieldPayload(updatePayload, key, this._updates[key]);
            }
        }
        await this._doc.updateDoc(updatePayload);
    }

    getPropertyValue(propName, defaultValue="") {
        if (this._updates[propName]) {
            return this._updates[propName];
        }
        if (this._doc && this._doc.data[propName] !== undefined) {
            return this._doc.data[propName];
        }
        return defaultValue;
    }

    setPropertyValue(propName, newValue) {
        this._updates[propName] = newValue;
    }
}

export default ModelBase;