import { DoubleRightOutlined, PartitionOutlined } from '@ant-design/icons';

// icons
const icons = {
    DoubleRightOutlined,
    PartitionOutlined
};

// ==============================|| MENU ITEMS - TEAM PAGES ||============================== //

const clubhouse = {
    id: 'team',
    title: 'Clubhouse',
    type: 'group',
    authOnly: true,
    children: [
        {
            id: 'club1',
            title: 'Club',
            type: 'item',
            url: '/club',
            icon: icons.PartitionOutlined,
            target: false
        },
        {
            id: 'team1',
            title: 'Team',
            type: 'item',
            url: '/team',
            icon: icons.DoubleRightOutlined,
            target: false
        }
    ]
};

export default clubhouse;
