import { lazy } from "react";

import Loadable from "../components/Loadable";
//import MainLayout from '../layout/MainLayout';
import LandingLayout from "../layout/LandingLayout";

const About = Loadable(lazy(() => import("pages/about")));

// render - login
const HeroGuide = Loadable(lazy(() => import("pages/hero-guide")));
const HeroVideoKit = Loadable(lazy(() => import("pages/hero-video-kit")));
const EvaluationsHero = Loadable(lazy(() => import("pages/evaluations-hero")));
const SpotlightsHero = Loadable(lazy(() => import("pages/spotlights-hero")));
const AvailabilityHero = Loadable(lazy(() => import("pages/availability-hero")));
const MobileLanding = Loadable(lazy(() => import("pages/mobile-landing")));
const IQHero = Loadable(lazy(() => import("pages/iq-hero")));
const Pricing = Loadable(lazy(() => import("pages/pricing")));
const Support = Loadable(lazy(() => import("pages/support")));
const Privacy = Loadable(lazy(() => import("pages/privacy")));
const Terms = Loadable(lazy(() => import("pages/terms")));

// ==============================|| AUTH ROUTING ||============================== //

const LoggedOutRoutes = {
    path: "/",
    element: <LandingLayout />,
    children: [
        {
            path: "terms",
            element: <Terms />
        },
        {
            path: "privacy",
            element: <Privacy />
        },
        {
            path: "about",
            element: <About />
        },
        {
            path: "mobile-landing",
            element: <MobileLanding />
        },
        {
            path: "hero-guide",
            element: <HeroGuide />
        },
        {
            path: "hero-video-kit",
            element: <HeroVideoKit />
        },
        {
            path: "IQ-hero",
            element: <IQHero />
        },
        {
            path: "evaluations-hero",
            element: <EvaluationsHero />
        },
        {
            path: "spotlights-hero",
            element: <SpotlightsHero />
        },
        {
            path: "availability-hero",
            element: <AvailabilityHero />
        },
        {
            path: "pricing",
            element: <Pricing />
        },
        {
            path: "support",
            element: <Support />
        }
    ]
};

export default LoggedOutRoutes;
