import { MailOutlined, ProfileOutlined } from '@ant-design/icons';

// icons
const icons = {
    MailOutlined,
    ProfileOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const managers = {
    id: 'managers',
    title: 'Managers Desk',
    type: 'group',
    authOnly: true,
    children: [
        {
            id: 'comms1',
            title: 'Messages',
            type: 'item',
            url: '/messages',
            icon: icons.MailOutlined,
            target: false
        }/*,
        {
            id: 'survey1',
            title: 'Surveys',
            type: 'item',
            url: '/surveys',
            icon: icons.ProfileOutlined,
            target: false
        }*/
    ]
};

export default managers;
