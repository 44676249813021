// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Button, Stack } from '@mui/material';
import { FBHERO } from '../../firebase';

// assets
import Google from 'assets/images/icons/google.svg';
import Apple from 'assets/images/icons/apple.svg';
import Facebook from 'assets/images/icons/facebook.svg';

// ==============================|| FIREBASE - SOCIAL BUTTON ||============================== //

const FirebaseSocial = ({ direction="row" }) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    const googleHandler = async () => {
        // login || singup
        FBHERO.signIn();
    };

    const appleHandler = async () => {
        // login || singup
    };

    const facebookHandler = async () => {
        // login || singup
    };

    return (
        <Stack
            direction={direction}
            spacing={matchDownSM ? 1 : 2}
            justifyContent={matchDownSM ? 'space-around' : 'space-between'}
            sx={{ '& .MuiButton-startIcon': { mr: matchDownSM ? 0 : 1, ml: matchDownSM ? 0 : -0.5 } }}
        >
            <Button
                variant="outlined"
                color="secondary"
                fullWidth={!matchDownSM}
                startIcon={<img src={Google} alt="Google" style={{ marginRight: "8px" }} />}
                onClick={googleHandler}
                sx={{textTransform: "none"}}
            >
                {(direction === "column" || !matchDownSM) && 'Continue with Google'}
            </Button>
            <Button
                variant="outlined"
                color="secondary"
                fullWidth={false}
                startIcon={<img src={Apple} alt="Apple" style={{ marginRight: "8px" }} />}
                onClick={appleHandler}
                sx={{textTransform: "none"}}
            >
                {(direction === "column" || !matchDownSM) && 'Continue with Apple'}
            </Button>
            <Button
                variant="outlined"
                color="secondary"
                fullWidth={!matchDownSM}
                startIcon={<img src={Facebook} alt="Facebook" style={{ marginRight: "8px" }} />}
                onClick={facebookHandler}
                sx={{textTransform: "none"}}
            >
                {(direction === "column" || !matchDownSM) && 'Continue with The Facebook'}
            </Button>
        </Stack>
    );
};

export default FirebaseSocial;
