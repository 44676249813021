import FrameCache from "./FrameCache";

import Whammy from 'react-whammy';

class RenderedFrameCache extends FrameCache {

    constructor(FPS) {
        super(FPS);
        this.name = "renderedCache";
    }

    _getFrameSlot(sec) {
        let frameFloat = sec * this._FPS;
        frameFloat = Math.round(1000 * frameFloat)/1000.0;

        const frameSlot = Math.floor(frameFloat);
        return frameSlot;
    }

    saveAsVideo(callback) {
        var encoder = new Whammy.Video(30);
        for(let i=0;i<this._frames.length;i++) {
            encoder.add(this._frames[i]);
        }

        encoder.compile(false, (output) => {
            const webmURL = URL.createObjectURL(output)
            callback(webmURL);
        });

    }

}

export default RenderedFrameCache;