
export const getContrastingTextColor = (color) => {
    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);

    // Calculate the relative luminance of the color
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // If the luminance is greater than 0.5, return black; otherwise, return white.
    return luminance > 0.5 ? "#000000" : "#FFFFFF";
};

export const getBoundingBox = (path) => {
    // Initialize min and max coordinates
    let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;

    // Get the path commands
    const commands = path.getCommands();

    // Iterate over each command
    for (let command of commands) {
        if (command.x !== undefined && command.y !== undefined) {
            // Update min and max x
            if (command.x < minX) minX = command.x;
            if (command.x > maxX) maxX = command.x;

            // Update min and max y
            if (command.y < minY) minY = command.y;
            if (command.y > maxY) maxY = command.y;
        }
    }

    // Return the bounding box
    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY
    };
};
