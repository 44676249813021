

import Document from "./Document";

class DocumentSet  {

    constructor(docRef, firestoreRefs) {
        this._firestoreRefs = firestoreRefs;
        this._docRef = docRef;
        this._documents = [];
    }

    addDocument(doc) {
        this._documents.push(doc);
    }

    get count() {
        return this._documents.length;
    }

    get documents() {
        return this._documents;
    }

    async createDocument(data) {
        const newRawDoc = await this._firestoreRefs.addDoc(this._docRef, data);
        const newDocRef = this._firestoreRefs.doc(this._firestoreRefs.firestoreDB, newRawDoc.path);
        const newDoc = new Document(data, this._firestoreRefs, newDocRef);
        return newDoc;
    }

}

export default DocumentSet;