import { makeGUID } from "utils/uid";
import SpotlightEvent from "./SpotlightEvent";

const DEFAULT_DURATION = 3.0;

function downloadFile(url, filename) {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename || "download";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

class Spotlight  {

    constructor(dataIn, metaIn) {
        this._data = dataIn || {
            id: makeGUID(),
            name: "Spotlight",
            desc: "",
            duration: (metaIn && metaIn.duration) || DEFAULT_DURATION,
            evts: {
                main: [{
                    id: makeGUID(),
                    k: SpotlightEvent.KIND_REFERENCE,
                    o: 0.0,
                    t: (metaIn && metaIn.playhead),
                    d: (metaIn && metaIn.duration) || DEFAULT_DURATION
                }],
                time: [{
                    id: makeGUID(),
                    k: SpotlightEvent.KIND_TIME_CONTROL,
                    o: 0.0,
                    t: (metaIn && metaIn.playhead)
                }]
            }
        };

        //remove these patches - just because of my dev spotlight
        if (this._data.id === undefined) {
            this._data.id = makeGUID();
        }
        if (!this._data.duration) {
            this._data.duration  = DEFAULT_DURATION;
        }
        //end remove patches

        this._events = {};

        //should create all the events objects
        for (const evtCat of Object.keys(this._data.evts)) {
            this.getSpotlightEvents(evtCat);
        }
    }

    //writes objects to data
    saveToData() {
        this._data.evts = {};
        for (const evtCat of Object.keys(this._events)) {
            this._data.evts[evtCat] = [];
            for (const spe of this._events[evtCat]) {
                this._data.evts[evtCat].push(spe.saveData());
            }
        }
        return this._data;
    }

    get id() {
        return this._data.id;
    }

    get time() {
        const evts = this.getSpotlightEvents("main");
        if (evts.length) {
            return evts[0].timecode;
        }
        return 0;
    }

    get offset () {
        //the first offset frame
        const evts = this.getSpotlightEvents("main");
        if (evts.length) {
            return evts[0].offset;
        }
        return 0;
    }

    get duration() {
        return this._data.duration;
    }

    get durationOld() {
        const mainEvts = this.getSpotlightEvents("main");
        const timeEvts = this.getSpotlightEvents("time");
        
        const mainContent = mainEvts[0];

        //the Main Content lane _is_ the time control lane... it will have to make global edits to the TL
        if (timeEvts.length > 0) {
            let durationSoFar = 0.0;
            let lastPlaySpeed = 1.0;
            let lastOffset = 0.0;
            let videoPlayheadRelative = 0.0;

            for (let i=0;i<timeEvts.length;i++) {
                const tmEvt = timeEvts[i];
                
                //offset is where it is on the spotlight timeline
                const timePassed = (tmEvt.offset - lastOffset);
                lastOffset = tmEvt.offset;

                console.log("time passed " + timePassed);

                durationSoFar += timePassed;
                videoPlayheadRelative += (timePassed * lastPlaySpeed);

                //clamp
                videoPlayheadRelative = Math.max(0, videoPlayheadRelative);
                videoPlayheadRelative = Math.min(mainContent.duration, videoPlayheadRelative);

                //now set the next in-play values
                if (tmEvt.timeControlAction === "pause") {
                    lastPlaySpeed = 0.0;

                } else {
                    if (tmEvt.playSemantic == "replay") {
                        videoPlayheadRelative -= 1.0;
                        lastPlaySpeed = 1.0;
                    } else if (tmEvt.playSemantic === "reverse") {
                        lastPlaySpeed = -1.0;
                    } else if (tmEvt.playSemantic === "play") {
                        lastPlaySpeed = 1.0;
                    } else if (tmEvt.playSemantic === "fastfwd") {
                        videoPlayheadRelative += 1.0;
                        lastPlaySpeed = 1.0;
                    }
                }
                //should we check to see if the playhead goes out of range? -> past mainContent.duration
            }
            if (lastPlaySpeed >= 0 && videoPlayheadRelative < mainContent.duration) {

                const videoTimeMakeup = mainContent.duration - videoPlayheadRelative;
                let timeLeft = videoTimeMakeup;
                if (lastPlaySpeed !== 0.0) {
                    timeLeft = videoTimeMakeup / lastPlaySpeed;
                }
                durationSoFar += timeLeft;
            } else if (lastPlaySpeed < 0 && videoPlayheadRelative > 0) {
                //it will play to the start
                durationSoFar += videoPlayheadRelative / -lastPlaySpeed;
            }
            console.log("returning duration == " + durationSoFar);
            return durationSoFar;
        }

        return mainContent.duration;
    }

    get thumbnail() {
        return null;
    }

    get name() {
        return "";
    }

    get description() {
        return "";
    }

    getSpotlightEvents(category) {
        if (!this._events[category]) {
            const catEvents = this._data.evts[category] || [];
            const catEventObjects = [];
            for (let i=0;i<catEvents.length;i++) {
                catEventObjects.push(new SpotlightEvent(catEvents[i]));
            }
            this._events[category] = catEventObjects;
        }
        return this._events[category];
    }

    _playheadTargetFrom(viewModel) {
        //maybe snap to a frame boundary?
        let newTarget = viewModel.activeFootageRef.timecode + viewModel.spotlightPlayhead - viewModel.activeFootageRef.offset;

        //needs to walk the time events list



        if (newTarget !== viewModel.playheadTarget) {
            viewModel.playheadTarget = newTarget;
            viewModel.videoheadTarget = newTarget;
            viewModel.fireEvent("seekVideo");
            return true;
        }
        return false;
    }

    //runs during init-animation
    setViewModelAtTime(viewModel, msTime) {
        
        //for each event category
        viewModel.activeFootageRef = undefined;
        viewModel.activeText = [];
        viewModel.activePlayerSpotlights = [];
        viewModel.activeTactical = [];

        let timeFound = 0;

        Object.keys(this._events).forEach((category) => {
            const evts = this._events[category];
            for (let i=0;i<evts.length;i++) {
                const evt = evts[i];

                //TBD this should be a rounded compare - also these times are not milliseconds
                if (evt.offset <= msTime && evt.offsetEnd > msTime) {
                    
                    if (evt.isFootageRef) {
                        timeFound++;
                        viewModel.activeFootageRef = evt;
                    } else if (evt.kind == SpotlightEvent.KIND_PLAYER_SPOTLIGHT) {
                        viewModel.activePlayerSpotlights.push(evt);
                    } else if (evt.kind === SpotlightEvent.KIND_TACTICAL) {
                        viewModel.activeTactical.push(evt);
                    } else if (evt.kind === SpotlightEvent.KIND_TEXT) {
                        viewModel.activeText.push(evt);
                    }
                }
            }
        });

        console.log("SVMAT " + msTime, { active: viewModel.activeFootageRef, timeFound });

        //adjust the playheadTarget given the activeFootageRef
        if (viewModel.activeFootageRef) {
            this._playheadTargetFrom(viewModel);
        }
    }

    _addSPE(viewModel, spotlightOffset, kind, category) {
        const spe = new SpotlightEvent();
        spe.offset = spotlightOffset;
        spe.duration = 1.0;
        spe.kind = kind;
        
        this._events[category] = this._events[category] || [];
        this._events[category].push(spe);
        return spe;
    }

    addTimeControl(viewModel, spotlightOffset) {
        const newSPE = this._addSPE(viewModel, spotlightOffset, SpotlightEvent.KIND_TIME_CONTROL, "time");
        newSPE.timeControlAction = "pause";
        
        //be smart here and set the kind of time control it does
        /*
        if (prev.playSpeed === 1.0 && !next) {
            //then add a pause keyframe

        } else if (prev.playSpeed === 0.0 && !next) {
            //if paused
            //then play fwd at 1.0
        } else if (prev.playSpeed < 0.0 && !next) {
            //playing backwards
            //then play fwd at 1.0
        } else if (prev.playSpeed > 0.0 && prev.playSpeed < 1.0 && !next) {
            //playing in slo motion
            //then play fwd at 1.0
        }
        */
        return newSPE;
    }

    addPlayerSpotlight(viewModel, spotlightOffset) {
        return this._addSPE(viewModel, spotlightOffset, SpotlightEvent.KIND_PLAYER_SPOTLIGHT, "player");
    }

    addText(viewModel, spotlightOffset) {
        return this._addSPE(viewModel, spotlightOffset, SpotlightEvent.KIND_TEXT, "text");
    }

    addTactical(viewModel, spotlightOffset) {
        const newTactical = this._addSPE(viewModel, spotlightOffset, SpotlightEvent.KIND_TACTICAL, "tactical");
        newTactical.tacticalSemantic = "arrow";
        newTactical.corners = [
            [-0.1, -0.1],
            [0.1, -0.1],
            [0.12, 0.1],
            [-0.15, 0.1],
        ];

        return newTactical;
    }

    clampTime(secIn, FPS) {
        const frame = Math.floor(secIn * FPS);
        return (frame / FPS);
    }
    
    /************ playDirector interface ****************/

    currentOffset(viewModel) {
        if (this._looping || this._recording) {//real-time
            const timeSinceMod = ((new Date().getTime() - this._timeZero)/1000.0) % this.duration;
            const timeSince = this.clampTime(timeSinceMod, viewModel.FPS);

            console.log("tick: " + timeSince + " vs. " + viewModel.getFrameLength());

            return timeSince;
        }

    }

    //TBD: revisit - do we really want frameSeeked?
    frameSeeked(viewModel) {
        this._frameSeeked = true;

    }


    //when we're in play director mode
    nextFrame(viewModel) {
        if (this._looping || this._recording) {
            //is the video done seeking?

            //console.log("should NF: ", {ph: viewModel.playhead, targ: viewModel.videoheadTarget});

            //these need rounding to compare properly... the playhead value is apparently rounded 

            if (viewModel.isVideoheadAt(viewModel.videoheadTarget)) {
                if (viewModel.frameCache.pctCached < 100.0) {
                    console.log("NEED TO SEEK TO NEXT VIDEO POS");
                    viewModel.videoheadTarget += viewModel.getFrameLength();
                    viewModel.fireEvent("seekVideo", { justVideo: true });
                }                
            }
        }
    }

    record(viewModel) {

        //init the cache and such

        //tell it to loop
        this._looping = false;
        this._recording = true;
        viewModel.playDirector = this;
        viewModel.drawVideoInOverlay = true;
        //not strictly necessary to have REC since we have viewModel.playDirector.isRecording, but viewModel.REC is easier to type
        viewModel.REC = true;
        this._timeZero = (new Date()).getTime();

        console.log("durations setting:", {fcDur: viewModel.frameCache.duration, rcDur: viewModel.renderedCache.duration, spDur: this.duration});

        viewModel.frameCache.duration = this.duration;
        viewModel.renderedCache.duration = this.duration;

        //listen for updates
        const handleCacheUpdated = (payload) => {
            console.log("CACHE UP [" + payload.name + "] ==> ", payload);

            if (payload.name === "renderedCache" && payload.pctCached >= 100.0) {
                console.log("WE DONE DONE DONE DONE DONEEEEEE!");
                this._recording = false;
                viewModel.playDirector = null;
                viewModel.drawVideoInOverlay = false;

                viewModel.REC = false;

                viewModel.renderedCache.saveAsVideo((webmURL) => {
                    downloadFile(webmURL, "potlight.webm");
                });
            }
        };
        viewModel.frameCache.addEventListener("cache-updated", handleCacheUpdated);
        viewModel.renderedCache.addEventListener("cache-updated", handleCacheUpdated);

        //first frame to render should be 0, which is at  videoheadTarget this.offset
        viewModel.videoheadTarget = this.offset;
        viewModel.playheadTarget = this.offset;
        viewModel.fireEvent("seekVideo");

        console.log("DO IT JENNY!!");
    }

    loop(viewModel) {
        viewModel.playDirector = this;
        this._timeZero = (new Date()).getTime();
        this._looping = true;
        this._recording = false;
        
        //TBD: clamp the playhead into our time... start seeking!

        this._frameSeeked = true;
    }

    pause(viewModel) {
        viewModel.playDirector = null;
        this._looping = false;
    }

}

export default Spotlight;