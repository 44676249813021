import { React, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Logo from 'components/Logo';

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Toolbar, useMediaQuery } from "@mui/material";
import FooterLinks from "components/Landing/FooterLinks";

// ==============================|| MOBILE LANDING PAGE LAYOUT ||============================== //

const MobileLandingLayout = () => {
    const theme = useTheme();

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    ackgroundImage: "linear-gradient(90deg,#d946ef,#fc0)",
                    backgroundColor: "#eab308",
                    width: "100%",
                    minHeight: "100vh",
                    padding: "20px"
                }}>
                <Box
                    component="main"
                    sx={{
                        width: "100%",
                        backgroundColor: "#ffffff",
                        flexGrow: 1,
                        p: { xs: 2, sm: 3 }
                    }}>
                    <Outlet />
                </Box>
            </Box>
            <Box sx={{
                top: "0px",
                left: "0px",
                ackgroundImage: "linear-gradient(90deg,#d946ef,#fc0)",
                backgroundColor: "#eab308",
                width: "100%",
                padding: "0px!important",
                minHeight: "20px",
                position: "fixed"
            }} />
        </>
    );
};

export default MobileLandingLayout;
