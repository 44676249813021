/**
 * Before they authenticate, they get...
 * - Homepage:
 * - Hero Guide:  
 * - Pricing: 
 * - Support:
 * 
 * 
 * If they have role === "coach" then they can see "Coach's Corner"
 * - Coach Profile (global)
 * - Club Homepage (per-club)
 * - Team Homepage (per-team)
 *   ) Manage Events
 *   ) Configure
 * 
 * - Session Plans (global)
 *   ) plan organizer
 *   ) new session plan
 * 
 * - Evaluations (global)
 *   ) in-progress (can filter by team)
 *   ) archive
 *   ) new evaluation
 * 
 * - Evaluation Groups (global)
 *   ) active (each team is automagicly an eval group)
 *   ) archive
 *   ) new evaluation group
 * 
 * 
 * If they have role === "manager" then they can see "Manager's Desk"
 * - Team Homepage
 *   ) Manage Events
 *   ) Configure
 * - Get Feedback
 *   ) Quick polls / questions / preferences for dates, foods, league or tournament selection (sometimes having cost components etc).
 * 
 * 
 * 
 * If they have role === "player" they can see "Player's Locker"
 * - Player Profile
 * - Club Homepage (per-club)
 * - Team Homepage (per-team)
 * - "In Position" App (future)
 * 
 * - Player Evaluations (global)
 *   ) Inbox
 *   ) Archived
 * - Player Highlights (future)
 * 
 * 
 * 
 * Everyone (with access to a Team) sees:
 * - Team Homepage
 *   ) League, Level, Coach
 *   ) Team Values & Goals
 *   ) Availability
 *   ) Sponsorship Level
 * 
 * - Club Homepage
 *   ) Teams
 *   ) Programs (w/signup)
 * 
 * - 
 */

class User  {

    constructor(user, userProfileDoc) {
        this._user = user;
        this._userProfileDoc = userProfileDoc;
        this.clubs = undefined;
    }

    get uid() {
        return this._user.uid;
    }

    //the API we check externally
    async hasEntitlement(entitlement) {

    }

    async getEntitlements() {
        //read-only sub-collection (writable only by the entitlement service)

    }

    get name() {
        return this._user.displayName;
    }

    get photoURL() {
        return this._user.photoURL;
    }

    hasRole(roleName) {
        return (this._userProfileDoc && this._userProfileDoc.data.roles && this._userProfileDoc.data.roles[roleName]);
    }

    get isOnboarded() {
        /*
        if (this._userProfileDoc && this._userProfileDoc.data.roles && (
            this._userProfileDoc.data.roles["coach"] ||
            this._userProfileDoc.data.roles["player"] ||
            this._userProfileDoc.data.roles["parent"] ||
            this._userProfileDoc.data.roles["manager"])) {

            if (this.clubs && this.clubs.length > 0) {
                return true;
            }
        }
        return false;
        */
       return true;
    }

    async enableUserRole(roleName) {
        await this._userProfileDoc.updateDocField("roles." + roleName, true);
    }

    //registrations
    async getRegistrations() {

        //registrations have multiple labels: Parent, Player, Coach, DOC

        //the same people can have 2 labels


    }

}

export default User;