import { React, useState, useEffect } from "react";
import { Container, Typography, Button } from '@mui/material';

function CookieConsent() {
  const [open, setOpen] = useState(true); // banner is open by default

  // If the user has already accepted, we won't show the banner
  useEffect(() => {
    if (localStorage.getItem('cookiesAccepted') === 'true') {
      setOpen(false);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setOpen(false);
  }

  if (!open) return null;

  return (
    <footer style={{backgroundColor: 'lightgray', padding: '20px', position: 'fixed', width: '100%', bottom: 0}}>
      <Container>
        <Typography variant="body1">
          Our website uses cookies to enhance your experience. By continuing to browse the site, you are agreeing to our use of cookies.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleAccept} style={{marginTop: '10px'}}>
          Accept
        </Button>
      </Container>
    </footer>
  );
}

export default CookieConsent;
