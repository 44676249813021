import { lazy } from "react";

import Loadable from "../components/Loadable";
//import MainLayout from '../layout/MainLayout';
import MobileLandingLayout from "../layout/MobileLandingLayout";

// render - login
const MobileLanding = Loadable(lazy(() => import("pages/mobile-landing")));

// ==============================|| AUTH ROUTING ||============================== //

const MobileRoutes = {
    path: "/",
    element: <MobileLandingLayout />,
    children: [
        {
            path: "mobile-landing",
            element: <MobileLanding />
        }
    ]
};

export default MobileRoutes;
