/**
 * 
 * Things are a little sideways because we shouldn't seek the video to a frame we have already cached... seek to a new frame.
 * 
 * 
 * 
 */

class FrameCache  {

    constructor(FPS) {
        this._offset = 0;
        this._duration = 0;
        this._frames = [];
        this._frameSet = 0;
        this._FPS = FPS;
        this._listeners = {};
        this.name = "frameCache";
    }

    clear() {
        this._frameSet = 0;
        this._frames = [];
        for(let i=0;i<(this.duration * this._FPS);i++) {
            this._frames.push(null);
        }
    }

    addEventListener(eventName, cbFunc) {
        this._listeners[eventName] = this._listeners[eventName] || [];
        this._listeners[eventName].push(cbFunc);
    }

    removeEventListener(eventName, cbFunc) {
        this._listeners[eventName].splice(this._listeners[eventName].indexOf(cbFunc), 1);
    }

    fireEvent(eventName, payload) {
        if (this._listeners[eventName]) {
            for (let i=0;i<this._listeners[eventName].length;i++) {
                this._listeners[eventName][i](payload);
            }
        }
    }

    get active() {
        return (this.duration > 0);
    }

    get offset() {
        return this._offset;
    }
    set offset(val) {
        this.clear();
        this._offset = val;
    }

    get duration() {
        return this._duration;
    }
    set duration(val) {
        this._duration = val;
        this.clear();
    }
    
    _getFrameSlot(sec) {
        let diffTime = sec - this._offset;
        //TBD: maybe this has an off by one error - TEST???

        let frameFloat = diffTime * this._FPS;
        frameFloat = Math.round(1000 * frameFloat)/1000.0;

        const frameSlot = Math.floor(frameFloat);
        return frameSlot;
    }

    getFrame(sec) {
        const frameSlot = this._getFrameSlot(sec);
        return this._frames[frameSlot];
    }

    setFrame(sec, frameData) {
        const frameSlot = this._getFrameSlot(sec);
        if (frameSlot >= 0 && frameSlot < this._frames.length) {
            this._frameSet++;
            
            console.log(this.name + " storing frame #" + frameSlot + " now " + this.pctCached + "%");

            this._frames[frameSlot] = frameData;

            this.fireEvent("cache-updated", {
                name: this.name,
                pctCached: this.pctCached
            });
        } else {
            //console.log("not storing frame, out of range");
        }
    }

    get pctCached() {
        return Math.round(1000 * (this._frameSet / this._frames.length))/10;
    }



}

export default FrameCache;