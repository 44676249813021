
import { React, useState } from "react";
import { Link } from 'react-router-dom';
import { Box, Grid, Stack, Typography, ThemeProvider, Modal } from '@mui/material';
import { themeModal, modalStyle } from "common-theme";
import AuthLogin from 'components/auth-forms/AuthLogin';
import AuthRegister from 'components/auth-forms/AuthRegister';

// ================================|| LOGIN ||================================ //

const SUSIDialog = ({ onComplete }) => {

    const [showSingup, setShowSignup] = useState(true);

    const handleClose = (evt, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
            //return;
        }
        onComplete();
    }

    return (
    <ThemeProvider theme={themeModal}>
        <Modal open={true} onClose={handleClose} className="modal-shell">
            <Box display="flex" justifyContent="center" sx={modalStyle}>
                {showSingup && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                            <Typography variant="h3">Sign up</Typography>
                            <Typography component={Link} onClick={() => { setShowSignup(false); }} variant="body1" sx={{ textDecoration: 'none' }} color="primary">
                                Already have an account?
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <AuthRegister />
                    </Grid>
                </Grid>)}
                {!showSingup && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                            <Typography variant="h3">Login</Typography>
                            <Typography component={Link} onClick={() => { setShowSignup(true); }} variant="body1" sx={{ textDecoration: 'none' }} color="primary">
                                Don&apos;t have an account?
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <AuthLogin />
                    </Grid>
                </Grid>)}
            </Box>
        </Modal>
    </ThemeProvider>);
};

export default SUSIDialog;
