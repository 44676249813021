
/**
 * Evaluations can be for one or more Players
 * May be tied to an Event or Program
 * 
 */

import ModelBase from "./ModelBase";

class Evaluation extends ModelBase {

    constructor(evaluationDoc) {
        super(evaluationDoc);
    }

    get name() {
        return this.getPropertyValue("name");
    }

    set name(newName) {
        this.setPropertyValue("name", newName);
    }

    get groupId() {
        if (this._group) {
            return this._group.id;
        }
        return 0;
    }

    get group() {
        return this._group;
    }

    set group(grp) {
        this.setPropertyValue("groupId", grp.id);
        this._group = grp;
    }

    get intro() {
        return this.getPropertyValue("intro");
    }

    set intro(newIntro) {
        this.setPropertyValue("intro", newIntro);
    }

    set jerseyNumber(newNumber) {
        this.setPropertyValue("jerseyNumber", newNumber);
    }

    get jerseyNumber() {
        return this.getPropertyValue("jerseyNumber");
    }

    getSectionData(sectionName, fieldName) {
        const sectionData = this.getPropertyValue(sectionName);
        if (sectionData) {
            return sectionData[fieldName];
        }
    }

    setSectionData(sectionName, fieldName, fieldValue) {
        const sectionData = this.getPropertyValue(sectionName) || {};
        sectionData[fieldName] = fieldValue;
        this.setPropertyValue(sectionName, sectionData);
    }

    //depending on the eval model, the data may take a different shape, so the data methods are relatively generic here.



    async create(docSet, userAdmin) {
        this._updates.roles = {};
        this._updates.roles[userAdmin.uid] = ["owner", "member"];

        this._doc = await docSet.createDocument(this._updates);
        this._updates = {};

        return this;
    }

    async save() {
        this.saveUpdates();
    }
}

export default Evaluation;