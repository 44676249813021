import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery, Stack, Button } from '@mui/material';

// project import
import Drawer from './Drawer';
import navigation from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import { HeaderProvider } from 'layout/HeaderContext';
import MobileNavbar from "components/Navbar/MobileNavbar";
import FooterLinks from 'components/Landing/FooterLinks';

import SUSIDialog from "components/auth-forms/SUSIDialog";
import Logo from 'components/Logo';

import { hasFeature } from "features";
import { hero } from "../../hero";


// types
import { openDrawer } from 'store/reducers/menu';
import { useNavigate } from "react-router-dom";

// ==============================|| MAIN LAYOUT ||============================== //

const HomepageLayout = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const dispatch = useDispatch();

    const [showSUSI, setShowSUSI] = useState(false);

    const handleSUSIComplete = () => {
        console.log("GOTTE");
    };

    const handleLoginClick = async () => {
        setShowSUSI(true);
    };

    const handleAccessClick = () => {
        navigate(`/home`);
    };

    const { drawerOpen } = useSelector((state) => state.menu);

    const appState = useSelector((state) => {
        return {
            loggedIn: state.appShell.loggedIn,
            isInitialized: state.appShell.isInitialized
        };
    });

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen]);

    return (<>
        {!hero.isMobile && (
            <HeaderProvider>
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    inHeight: "2400px",
                    minHeight: "calc(100vh - 234px)"
                    }}>
                    <Box sx={{
                        position: "absolute",
                        zIndex: "100",
                        top: "12px",
                        left: isSmallScreen ? "44px" : "calc(2rem + 44px)",
                        right: "calc(2rem + 24px)",
                        maxWidth: "1186px",
                        margin: "0 auto"
                    }}>
                        <Box sx={{
                            borderRadius: "8px",
                            padding: "4px 12px",
                            display: "inline-block",
                            backgroundColor: "rgba(255,255,255, 0.5)"
                        }}>
                            <Logo />
                        </Box>
                        { (appState.loggedIn && hasFeature("home_login")) && (<Box sx={{
                            position: "absolute",
                            display: "inline-block",
                            right: "20px"
                            }}>
                            <Button onClick={handleAccessClick}>Access</Button>
                        </Box>)}
                        { (!appState.loggedIn && hasFeature("home_login")) && (<Box sx={{
                            position: "absolute",
                            display: "inline-block",
                            right: "20px"
                            }}>
                            <Button onClick={handleLoginClick}>Sign In</Button>
                        </Box>)}
                    </Box>
                    <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                        <Outlet />
                    </Box>
                </Box>
                <FooterLinks />
                { showSUSI && <SUSIDialog onComplete={handleSUSIComplete}></SUSIDialog> }
            </HeaderProvider>
        )}
        {hero.isMobile && (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh'
            }}>
                <Box component="main" sx={{
                    width: '100%',
                    flexGrow: 1,
                    overflowY: 'auto'
                }}>
                    <Toolbar />

                    {/**<Breadcrumbs navigation={navigation} title />**/}
                    <Outlet />
                </Box>
                <Box sx={{
                    height: '200px', // Fixed height for navbar
                    borderTop: "1px solid #333",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <MobileNavbar />
                </Box>
            </Box>
        )}
        </>);
};

export default HomepageLayout;
