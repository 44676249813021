
import ModelBase from "model/ModelBase";
import GameVideoViewModel from "./GameVideoViewModel";
import ReframeKeyframe from "./ReframeKeyframe";
import Timeline from "./Timeline";
import GameSetupKeyframe from "./GameSetupKeyframe";
import GameEvent from "./GameEvent";
import Spotlight from "./Spotlight";

let instanceCounter = 0;

class GameVideo extends ModelBase {

    static DOC_SET = "gamevideos";

    constructor(doc) {
        super(doc);

        this._instance = instanceCounter++;

        //populated on first-access
        this._keyframes = undefined;
        this._spotlights = undefined;

        this._viewModel = new GameVideoViewModel(doc);
        this._timeline = new Timeline(this);
    }

    get name() {
        return this.getPropertyValue("name");
    }

    set name(newName) {
        this.setPropertyValue("name", newName);
    }

    //support local video mode
    get hasVideoSource() {
        const videoURL = this.getPropertyValue("videoURL", "");
        if (videoURL) {
            console.log("FOUND VIDEO URL (unexpected)");
            return true;
        }
        return false;
    }

    get localVideoFilename() {
        return this.getPropertyValue("localVideoFilename", "");
    }

    set localVideoFilename(filename) {
        this.setPropertyValue("localVideoFilename", filename);
    }

    get localVideoFilesize() {
        return this.getPropertyValue("localVideoFilesize", 0);
    }

    set localVideoFilesize(size) {
        this.setPropertyValue("localVideoFilesize", size);
    }

    get localVideoURL() {
        return this._localVideoURL;
    }

    set localVideoURL(url) {
        this._localVideoURL = url;
    }

    get editorVideoURL() {
        return this._localVideoURL || this.videoURL;
    }

    get videoURL() {
        //return this.getPropertyValue("videoURL", "/static/match-demo.mp4");
        return this.getPropertyValue("videoURL", "");
    }

    set videoURL(newVideoURL) {
        this.setPropertyValue("videoURL", newVideoURL);
    }

    get timeline() {
        return this._timeline;

    }

    addOrEditKeyframe(keyframeObj) {

        if (!keyframeObj.isNew) {
            console.log("addOrEdit is not on a new KF, so returning after updating th emodel");

            this.timeline.setTime(-1);//re-calc the current & next kfs
            this._viewModel.fireEvent("timeline-updated");
            return;
        }

        //we're inserting it, so it isn't "new" any more
        keyframeObj.isNew = false;

        //we should have a cached insert position, but need to verify based on the keyframe data

        //start with a dumb search
        const kfs = this.keyframes;
        let insertPos = 0;
        for (insertPos=0;insertPos<kfs.length;insertPos++) {
            if (keyframeObj.time < kfs[insertPos].time) break;
        }

        //insert the new keyfram
        kfs.splice(insertPos, 0, keyframeObj);

        //console.log("INSERTED is it ok? ", kfs);

        this.keyframes = kfs;
        
        //fire an event so that the timeline updates
        if (this._timeline) {
            this._timeline.setTime(-1);//re-calc the current & next kfs
        }
        this._viewModel.fireEvent("timeline-updated");
    }

    get currentGameState() {
        return this._timeline?._gameEventCurrent?.actionName;
    }

    get periodInformation() {
        const periods = [];

        let periodCurrent = null;
        const keyframes = this.keyframes;
        //scan Game Events for period boundaries
        for (const kf of keyframes) {
            if (kf.isGameEvent) {
                if (kf.actionName === "kickoff") {
                    //end the previous period before a new kickoff
                    if (periodCurrent) {
                        periodCurrent.endOffset = kf.time;
                        periodCurrent.endPct = 100.0 * periodCurrent.endOffset / this._viewModel.videoMeta.duration;
                    }

                    periodCurrent = {
                        startOffset: kf.time,
                        startPct: 100.0 * kf.time / this._viewModel.videoMeta.duration,
                        endOffset: this._viewModel.videoMeta.duration,
                        endPct: 100.0
                    };
                    periods.push(periodCurrent);

                } else if (kf.actionName === "period") {
                    periodCurrent.endOffset = kf.time;
                    periodCurrent.endPct = 100.0 * periodCurrent.endOffset / this._viewModel.videoMeta.duration;
                    periodCurrent = undefined;
                } else {
                    //console.log("skipping action name == " + kf.actionName);
                }
            }
        }

        if (periods.length === 0) {
            const defaultPeriod = {
                startOffset: 0,
                startPct: 0.0,
                endOffset: this._viewModel.videoMeta.duration,
                endPct: 100.0
            };
            periods.push(defaultPeriod);
        }

        return {
            periods
        };

    }

    //all in the main document
    get keyframes() {

        //create keyframe objects on demand
        if (!this._keyframes) {
            if (!this._doc) {
                return [];
            }
            //console.log("creating keyframes from data" + this._instance, JSON.stringify(this._keyframes));

            this._keyframes = [];
            //turn the data into Objects
            const keyframesData = this._doc.data.keyframes || [];
            for (let i=0;i<keyframesData.length;i++) {
                const keyframeData = keyframesData[i];
                let keyframeObj;
                switch(keyframeData.a) {
                    case ReframeKeyframe.TYPE:
                        keyframeObj = new ReframeKeyframe(keyframeData);
                        break;
                    case GameSetupKeyframe.TYPE:
                        keyframeObj = new GameSetupKeyframe(keyframeData);
                        break;
                    case GameEvent.TYPE:
                        keyframeObj = new GameEvent(keyframeData);
                        break;
                    default:
                        console.log("unknown keyframedata type", keyframeData);
                        break;
                };
                if (keyframeObj) {
                    this._keyframes.push(keyframeObj);
                }
            }
        }

        return this._keyframes;
    }

    set keyframes(kf) {
        this._keyframes = kf.slice();
    }


    //all in the main document
    //note: spotlight artifacts are separate documents
    get spotlights() {
        if (!this._spotlights) {
            this._spotlights = [];
            const spotlightsData = this._doc.data.spotlights || [];
            for (let i=0;i<spotlightsData.length;i++) {
                const spotlightData = spotlightsData[i];
                let spotlightObj = new Spotlight(spotlightData);

                this._spotlights.push(spotlightObj);
            }
        }
        return this._spotlights;
        
    }

    set spotlights(spotlights) {
        this._spotlights = spotlights;
    }

    addSpotlight(spotlight) {
        //insert at the right index...
        const spots = this.spotlights;
        let insertPos = 0;
        for (insertPos=0;insertPos<spots.length;insertPos++) {
            if (spotlight.time < spots[insertPos].time) break;
        }

        //insert the new spotlight
        spots.splice(insertPos, 0, spotlight);

        this.spotlights = spots;
        
        this._viewModel.fireEvent("timeline-updated");
    }

    async save() {
        //the keyframes and spotlights properties need model/object transcription 
        let kfsData = [];
        for (let i=0;this._keyframes && i<this._keyframes.length;i++) {
            const kfData = this._keyframes[i].saveAsData();
            kfsData.push(kfData);
        }
        this.setPropertyValue("keyframes", kfsData);

        let spotlightsData = [];
        for (const spotlight of this._spotlights) {
            const spotlightData = spotlight.saveToData();
            spotlightsData.push(spotlightData);
        }
        this.setPropertyValue("spotlights", spotlightsData);
        
        //console.log("ABOUT TO SAVE:", this._data);

        //now, all the properties in this._updates should get sent...
        await this.saveUpdates();
    }

    async createGameVideo(docSet, userAdmin) {
        this._updates.roles = {};
        this._updates.roles[userAdmin.uid] = ["owner", "member"];
        
        this._doc = await docSet.createDocument(this._updates);
        this._updates = {};
        return this;
    }

    get viewModel() {
        return this._viewModel;
    }

}

export default GameVideo;