import { useRoutes } from 'react-router-dom';

import HomeRoutes from './HomeRoutes';
import MobileRoutes from './MobileRoutes';
import LoginRoutes from './LoginRoutes';
import LoggedOutRoutes from './LoggedOutRoutes';
import UserRoutes from './UserRoutes';

export default function AppRoutes() {
    return useRoutes([HomeRoutes, MobileRoutes, LoginRoutes, LoggedOutRoutes, UserRoutes]);
}
