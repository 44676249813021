import FrameCache from "./FrameCache";

class VideoFrameCache extends FrameCache {

    constructor(FPS) {
        super(FPS);
    }

    //return the time of the next uncached frame
    nextUncachedFrame(timeOffset, framesToCheck) {
        let slot = this._getFrameSlot(timeOffset);
        let ittr = 0;
        while(ittr++ < framesToCheck && (slot + ittr) < this._frames.length) {
            if (!this._frames[slot + ittr]) {
                return (this._offset + (slot + ittr) / this._FPS);
            }
        }
        return timeOffset;
    }

    getPreviousRenderedFrame(timeOffset) {
        let slot = this._getFrameSlot(timeOffset);
        let ittr = 0;
        while((slot + ittr--) >= 0) {
            if (this._frames[slot + ittr]) {
                return this._frames[slot + ittr];
                //return (this._offset + (slot + ittr) / this._FPS);
            }
        }
        return null;
    }
}

export default VideoFrameCache;