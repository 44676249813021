//hero singleton for the App

import { FBHERO } from "./firebase";
import User from "./model/User";
import Club from "./model/Club";
import Session from "./model/Session";
import GameVideo from "model/GameVideo/GameVideo";
import Evaluation from "model/Evaluation";
import EvaluationGroup from "model/EvaluationGroup";
import Team from "model/Team";

const factories = {
    createTeam: (teamDoc, teamDocSet) => {
        return new Team (teamDoc, teamDocSet);
    },

    createSession: (sessionDoc, sessionDocSet) => {
        return new Session (sessionDoc, sessionDocSet);
    },

    createGameVideo: (gameVideoDoc, gameVideoDocSet) => {
        return new GameVideo(gameVideoDoc, gameVideoDocSet);
    }
};


function extractAndRemoveQueryParams(paramNames) {

    //console.log("HREF: " + window.location.href);

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
  
    // Extract the specified parameters
    const extractedParams = {};
    paramNames.forEach(name => {
      if (params.has(name)) {
        //extractedParams[name] = decodeURIComponent(params.get(name));
        extractedParams[name] = params.get(name);
        params.delete(name);
      }
    });

    // Update the URL without the extracted parameters

    //url.search = params.toString();
    //window.history.replaceState({}, '', url.toString());

    //save remaining params 
    hero.queryParams = params;

    return extractedParams;
}

export const hero = {
    FBHERO: FBHERO,
    
    initialized: (cb) => {

        //ingest query parameters
        try {
            const queryParams = extractAndRemoveQueryParams(['token', 'provider', 'mobile']);
            hero.authProvider = queryParams.provider;
            
            hero.authToken = queryParams.token;
            hero.mobileId = queryParams.mobile;
            
            hero.isMobile = (queryParams.mobile && queryParams.mobile !== "false");
        } catch (exx) {
            console.warn("caught " + exx);
        }

        const withUser = (user) => {
            if (user) {
                //give this a user.uid name
                FBHERO.loadOrCreateDoc("userdata/" + user.uid, () => {
                    const initProfileData = {
                        joined: (new Date()).getTime(),
                        roles: {}
                    };
                    return initProfileData;
                }, (userProfileDoc) => {
                    hero.user = new User(user, userProfileDoc);
                    
                    cb();
                    /*
                    if (hero.clubs) {
                        hero.user.clubs = hero.clubs;
                        cb();
                    }
                    */
                });

                //.teams
                //const teamPromise = FBHERO.queryDocSet("teams", "members", factories.createTeam);


                //.clubs
                /*
                loadDocSet("clubs", (docSet) => {
                    console.log("LOADED SO MANY CLUBS", docSet);

                    const clubs = [];
                    for (let i=0; i< docSet.count; i++) {
                        clubs.push(new Club(docSet.documents[i], docSet));
                    }
                    hero.clubs = clubs;
                    hero.clubsDocSet = docSet;
                    if (hero.user) {
                        hero.user.clubs = hero.clubs;
                        cb();
                    }
                });
                */

                //one of the registrations can be for a season, that links it to a team

            } else {
                cb();
            }
        };

        if (hero.isMobile && hero.authToken) {
            //authenticate with token...
            FBHERO.checkUserAuth((user) => {
                if (!user) {
                    console.log("TOKEN AUTH NOW");
                    FBHERO.authWithToken(hero.authToken, hero.authProvider, withUser);
                } else {
                    withUser(user);
                }
            });
        } else {
            FBHERO.checkUserAuth(withUser);
        }
    },

    user: undefined,

    _listeners: {},

    addEventListener: (eventName, cbFunc) => {
        hero._listeners[eventName] = hero._listeners[eventName] || [];
        hero._listeners[eventName].push(cbFunc);
    },

    removeEventListener: (eventName, cbFunc) => {
        hero._listeners[eventName].splice(hero._listeners[eventName].indexOf(cbFunc), 1);
    },

    fireEvent: (eventName, payload) => {
        if (hero._listeners[eventName]) {
            for (let i=0;i<hero._listeners[eventName].length;i++) {
                hero._listeners[eventName][i](payload);
            }
        }
    },

    getDocSet: async (docSetName) => {
        return FBHERO.getDocSet(docSetName);
    },

    getSessions: async () => {
        return FBHERO.getObjects("sessions", factories.createSession);
    },

    getGameVideos: (cb) => {
        return FBHERO.getObjects(GameVideo.DOC_SET, factories.createGameVideo);
    },

    getOLDGameVideos: (cb) => {
        if (hero.gameVideos) {
            cb(hero.gameVideos);
            return;
        }

        const gameVideosLoaded = (gameVideos)  => {
            cb(gameVideos);
            hero.removeEventListener("gamevideosloaded", gameVideosLoaded);
        };

        hero.addEventListener("gamevideosloaded", gameVideosLoaded);

        if (!hero._hasStartedLoadingGameVideos) {
            hero._hasStartedLoadingGameVideos = true;

            loadDocSet("gamevideos", (docSet) => {
                const gameVideos = [];
                for (let i=0; i< docSet.count; i++) {
                    const newGV = new GameVideo(docSet.documents[i], docSet);
                    gameVideos.push(newGV);
                    
                }
                hero.gameVideos = gameVideos;
                hero.gameVideosDocSet = docSet;
                hero.fireEvent("gamevideosloaded", gameVideos);
            });
        }
    },


    //THIS IS FOR LOADING A PLAYER'S EVALUATIONS & SHOULD ONLY RETURN EVALS WHERE THEY ARE THE SUBJECT
    getEvaluations: (cb) => {
        if (hero.evaluations) {
            cb(hero.evaluations);
            return;
        }

        const evaluationsLoaded = (evaluations)  => {
            cb(evaluations);
            hero.removeEventListener("evaluationsloaded", evaluationsLoaded);
        };

        hero.addEventListener("evaluationsloaded", evaluationsLoaded);

        if (!hero._hasStartedLoadingEvaluations) {
            hero._hasStartedLoadingEvaluations = true;

            loadDocSet("evaluations", (docSet) => {
                const evaluations = [];
                for (let i=0; i< docSet.count; i++) {
                    const newEval = new Evaluation(docSet.documents[i]);
                    evaluations.push(newEval);
                }
                hero.evaluations = evaluations;
                hero.evaluationsDocSet = docSet;
                hero.fireEvent("evaluationsloaded", evaluations);
            });
        }
    },

    //THIS IS FOR LOADING THE EVALUATIONS VIEW FOR COACHES
    getEvaluationGroups: (cb) => {
        if (hero.evaluationGroups) {
            cb(hero.evaluationGroups);
            return;
        }

        const evaluationGroupsLoaded = (evaluationGroups)  => {
            cb(evaluationGroups);
            hero.removeEventListener("evaluationgroupsloaded", evaluationGroupsLoaded);
        };

        hero.addEventListener("evaluationgroupsloaded", evaluationGroupsLoaded);

        if (!hero._hasStartedLoadingEvaluationGroups) {
            hero._hasStartedLoadingEvaluationGroups = true;

            loadDocSet("evaluationgroups", (docSet) => {
                const evaluationGroups = [];
                for (let i=0; i< docSet.count; i++) {
                    const newEvalGroup = new EvaluationGroup(docSet.documents[i], hero);
                    evaluationGroups.push(newEvalGroup);
                }
                hero.evaluationGroups = evaluationGroups;
                hero.evaluationGroupsDocSet = docSet;
                hero.fireEvent("evaluationgroupsloaded", evaluationGroups);
            });
        }
    },

    getEvaluationsForGroup: async (group, cb) => {
        const evalDocSet = await FBHERO.getDocSet("evaluations");
        hero.evaluationsDocSet = evalDocSet;

        console.log("QUERY EVALS FOR GROUP: ", group);
            
        FBHERO.loadRelatedDocs(group.id, "evaluations", "groupId", (docObj) => {
            //create doc from data
            const evalObj = new Evaluation(docObj);
            evalObj.group = group;
            return evalObj;
        }, (evalList)=> {
            console.log("RESULTS FOR GROUP " + group.id, evalList);

            //TBD: need to process this list??
            cb(evalList);
        });
    },

    saveEarlyAccessEmail: (emailAddress) => {
        return FBHERO.saveEmailToFirestore(email);
    },

    getTeams: async () => {
        return FBHERO.getObjects("teams", factories.createTeam);
    },

    createTeam: async (teamData) => {
        const team = await FBHERO.createObject("teams", teamData, ["owner", "member"], factories.createTeam, hero.teams);        
        return team;
    }
};