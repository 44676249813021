
import { getContrastingTextColor } from "./utils";

class GameSetupKeyframe {

    static TYPE = "gs";

    constructor(keyframeData) {
        if (!keyframeData) {
            this.isNew = true;
            this._data = {
                a: GameSetupKeyframe.TYPE,
                home: "HME",
                homeColor: "#336699",
                homeLogo: "",
                away: "AWY",
                awayColor: "#222222",
                awayLogo: "",
                gameModel: "11v11",
                halfLength: 35,
                t: 0
            };
        } else {
            this._data = keyframeData;
        }
    }

    get isGameEvent() {
        return true;
    }

    saveAsData() {
        return this._data;
    }

    get time() {
        return this._data.t;
    }

    get actionName() {
        return "Game Setup";
    }

    get gameModel() {
        return this._data.gameModel;
    }

    set gameModel(val) {
        this._data.gameModel = val;
    }

    get halfLength() {
        return this._data.halfLength;
    }

    set halfLength(val) {
        this._data.halfLength = val;
    }

    get homeMoniker() {
        return this._data.home;
    }

    set homeMoniker(val) {
        this._data.home = val;
    }

    get homeColor() {
        return this._data.homeColor;
    }

    set homeColor(val) {
        this._data.homeColor = val;
    }

    get awayMoniker() {
        return this._data.away;
    }

    set awayMoniker(val) {
        this._data.away = val;
    }

    get awayColor() {
        return this._data.awayColor;
    }

    set awayColor(val) {
        this._data.awayColor = val;
    }

    setViewModel(viewModel) {
        viewModel.gameMeta.halfLength = this._data.halfLength;
        viewModel.gameMeta.home.moniker = this._data.home;
        viewModel.gameMeta.home.color = this._data.homeColor;
        viewModel.gameMeta.home.contrast = getContrastingTextColor(viewModel.gameMeta.home.color);
        viewModel.gameMeta.away.moniker = this._data.away;
        viewModel.gameMeta.away.color = this._data.awayColor;
        viewModel.gameMeta.away.contrast = getContrastingTextColor(viewModel.gameMeta.away.color);
    }
}

export default GameSetupKeyframe;
