import React from "react";
import './App.css';
import ThemeCustomization from './themes';
import ScrollTop from "./components/ScrollTop";
import Routes from "./routes";
import { hero } from "./hero";
import { dispatch } from "./store";
import { onAuthUpdate } from "./store/reducers/appShell";
import CookieConsent from "components/CookieConsent";
import ErrorBoundary from "components/ErrorBoundary";

let isInitialized = false;

function awaitInit() {
    if (isInitialized) return;
    isInitialized = true;
    hero.initialized(() => {
        dispatch(onAuthUpdate({
            loggedIn: !!hero.user
        }));
    });
}

function App () {
    awaitInit();
    return (
        <React.Suspense fallback={<>...</>}>
            <ErrorBoundary>
                <ThemeCustomization>
                    <ScrollTop>
                        <Routes />
                    </ScrollTop>
                    <CookieConsent />
                </ThemeCustomization>
            </ErrorBoundary>
        </React.Suspense>
    );
}
export default App;
