import DocumentSet from "./DocumentSet";

class Document  {
    constructor(docData, firestoreRefs, docRef) {
        this._docData = docData;

        this._firestoreRefs = firestoreRefs;

        //tbd: can ditch these redundant references to firestore functions
        this._updateDoc = firestoreRefs.updateDoc;
        this._setDoc = firestoreRefs.setDoc;
        this._collection = firestoreRefs.collection;
        this._getDocs = firestoreRefs.getDocs;
        this._addDoc = firestoreRefs.addDoc;
        
        this._docRef = docRef;

        this._subCollectionPromises = {};
        this._docSets = {};
    }

    get id() {
        return this._docRef.id;
    }

    get data() {
        return this._docData;
    }

    async getSubCollection(subCollectionName) {
        if (this._subCollectionPromises[subCollectionName]) {
            return this._subCollectionPromises[subCollectionName];
        }

        const subCollectionRef = this._collection(this._docRef, subCollectionName);
 
        this._subCollectionPromises[subCollectionName] = this._getDocs(subCollectionRef).then((resultDocs) => {
            const docSet = new DocumentSet(subCollectionRef, this._addDoc);
            resultDocs.forEach((doc) => {
                docSet.addDocument(new Document(doc.data(), this._firestoreRefs, doc.ref));
            });
            this._docSets[subCollectionName] = docSet;
            return docSet;
        });

        
    }

    async updateDoc(incrementalUpdate) {
        await this._updateDoc(this._docRef, incrementalUpdate);
    }

    async updateDocField(fieldName, fieldValue) {
        const incrementalUpdate = this.updateDocFieldPayload({}, fieldName, fieldValue);
        await this.updateDoc(incrementalUpdate);
    }

    updateDocFieldPayload(payload, fieldName, fieldValue) {
        payload[fieldName] = fieldValue;
        return payload;
    }

}

export default Document;