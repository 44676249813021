import { React, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Logo from 'components/Logo';

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Toolbar, useMediaQuery } from "@mui/material";
import FooterLinks from "components/Landing/FooterLinks";

// ==============================|| LANDING PAGE LAYOUT ||============================== //

const LandingLayout = () => {
    const theme = useTheme();

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    ackgroundImage: "linear-gradient(90deg,#d946ef,#fc0)",
                    backgroundColor: "#eab308",
                    width: "100%",
                    minHeight: "100vh",
                    padding: "20px"
                }}>
                <Box
                    component="main"
                    sx={{
                        width: "100%",
                        backgroundColor: "#ffffff",
                        flexGrow: 1,
                        p: { xs: 2, sm: 3 }
                    }}>
                    <Outlet />
                </Box>
            </Box>
            <Box sx={{
                top: "0px",
                left: "0px",
                ackgroundImage: "linear-gradient(90deg,#d946ef,#fc0)",
                backgroundColor: "#eab308",
                width: "100%",
                padding: "0px!important",
                minHeight: "20px",
                position: "fixed"
            }} />
            <Toolbar
                sx={{
                    top: "20px",
                    left: "0px",
                    width: "100%",
                    padding: "0px 20px 0px 20px!important",
                    minHeight: "20px",
                    position: "fixed"
                }}>
                    <Box sx={{
                        backgroundColor: "rgba(255,255,255,0.8)",
                        width: "100%",
                        padding: "10px 40px 10px 40px",
                        borderBottom: "1px solid rgba(0,0,0,0.1)"
                    }}>
                        <Logo />
                        
                    </Box>
            </Toolbar>
            <FooterLinks />
        </>
    );
};

export default LandingLayout;
