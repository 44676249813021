import ReframeKeyframe from "./ReframeKeyframe";
import GameSetupKeyframe from "./GameSetupKeyframe";

/**
 * 
 * There should be a GameEvent stuck to t0 that provides:
 * Team Names, Colors & Logos
 * Game Model & Half-length
 * 
 */


const EASE_IN_TIME_STD = 0.5;

const SNAP_TO_TIME = 1.0;

class Timeline  {

    constructor(gameVideo) {
        this.gameVideo = gameVideo;
        this._currentTime = -1;
        this._resetKeyframeRefs();

        if (this.gameVideo.keyframes.length === 0) {
            //we always have a keyframe at t=0, it can be edited, but not deleted.
            this.gameVideo.addOrEditKeyframe(new ReframeKeyframe());

            //we always have a GameEvent at t=0, it can be edited, but not deleted.
            this.gameVideo.addOrEditKeyframe(new GameSetupKeyframe());
        }
    }

    _resetKeyframeRefs() {
        this._keyframeInsertIndex = 0;
        this._keyframeCurrent = null;
        this._keyframeNext = null;

        this._gameEventInsertIndex = 0;
        this._gameEventCurrent = null
        this._gameEventNext = null;
    }

    get editOrAddKeyframe() {
        //debugger;
        //this.setTime(-1);
        console.log("EDIT OR ADD...");

        const diffCurrent = Math.abs(this._keyframeCurrent.time - this._currentTime);
        let diffNext =  -1;
        if (this._keyframeNext) {
            diffNext = Math.abs(this._keyframeNext.time - this._currentTime);

            console.log("have diffNext=" + diffNext);
        }
        if (diffNext >= 0 && diffNext < SNAP_TO_TIME && diffNext < diffCurrent) {

            console.warn("RETURN KF NEXT", this._keyframeNext);

            return this._keyframeNext;
        } else if(diffCurrent < SNAP_TO_TIME) {

            if (this._keyframeCurrent.time !== 0) {
                console.log("moving time, maybe no bueno");
                this._keyframeCurrent.time = this._currentTime;
            }

            console.log("returning current ", this._keyframeCurrent);

            return this._keyframeCurrent;
        } else {

            console.log("fall through", { diffNext, diffCurrent, SNAP_TO_TIME});
        }
        const newKF = new ReframeKeyframe();
        newKF.time = this._currentTime;
        return newKF;
    }

    //set time does not alter the playhead values in the view model,
    //it just aligns the keyframe pointers in this view.
    setTime(msTime) {
        if (msTime !== -1) {
            if (msTime === this._currentTime) {
                return;
            }
            this._currentTime = msTime;
        } else {
            msTime = this._currentTime;
        }
        const keyframes = this.gameVideo.keyframes;
        this._resetKeyframeRefs();

        //track kick-offs
        let currentHalf = 0;
        let currentHalfStart = 0;
        let currentHalfEnd = 0;
        let isPeriodOver = false;
        let scoreHome = 0;
        let scoreAway = 0;

        for (let i=0;i<keyframes.length;i++) {
            const kf = keyframes[i];
        
            if (kf.time > msTime) {
                //we've found the spot
                if (kf.isReframe) {
                    this._keyframeNext = kf;
                    this._keyframeInsertIndex = i;
                } else if (kf.isGameEvent) {
                    this._gameEventNext = kf;
                    this._gameEventInsertIndex = i;
                } else {
                    console.warn("unknown kf", kf);
                }
                break;
            } else {
                if (kf.isReframe) {
                    this._keyframeCurrent = kf;
                } else if (kf.isGameEvent) {
                    this._gameEventCurrent = kf;

                    if (kf.actionName === "kickoff") {
                        currentHalf++;
                        currentHalfStart = kf.time;
                        isPeriodOver = false;
                    } else if (kf.actionName === "period") {
                        isPeriodOver = true;
                        currentHalfEnd = kf.time;
                    } else if (kf.actionName === "goal") {
                        if (kf.metadata === "home") {
                            scoreHome++;
                        } else {
                            scoreAway++;
                        }
                    }

                } else {
                    console.warn("unknown kf", kf);
                }
            }
        }

        const viewModel = this.gameVideo.viewModel;
        viewModel.gameMeta.currentHalf = currentHalf;
        viewModel.gameMeta.startHalf = currentHalfStart;
        viewModel.gameMeta.endHalf = currentHalfEnd;
        viewModel.gameMeta.isPeriodOver = isPeriodOver;
        viewModel.gameMeta.home.score = scoreHome;
        viewModel.gameMeta.away.score = scoreAway;
    }

    updateViewModel() {
        const viewModel = this.gameVideo.viewModel;
        const msTime = viewModel.playheadTarget;
        this.setTime(msTime);

        //figure out the keyframes and easing for the zoom & focus point & put it in the view model, then set vm dirty
        if (this._keyframeNext && (this._keyframeNext.time - msTime) < EASE_IN_TIME_STD) {

            //consider playing a css animation to next?
            //- would need to track currentlyPlayingAnimation

            //easing
            const msDistTotal = Math.min(EASE_IN_TIME_STD, (this._keyframeNext.time - this._keyframeCurrent.time));
            const msDistRemain = this._keyframeNext.time - msTime;

            const pctTransition = 1 - (msDistRemain / msDistTotal);

            /*
            console.log("next kf:", {
                pctDone: pctTransition, 
                diffNext: ((this._keyframeNext.time - msTime))
            });
            */
            viewModel.zoomLevel = this._keyframeCurrent.zoomLevel + pctTransition * (this._keyframeNext.zoomLevel - this._keyframeCurrent.zoomLevel);

            viewModel.focusPoint = {
                x: this._keyframeCurrent.focusPoint.x + pctTransition * (this._keyframeNext.focusPoint.x - this._keyframeCurrent.focusPoint.x),
                y: this._keyframeCurrent.focusPoint.y + pctTransition * (this._keyframeNext.focusPoint.y - this._keyframeCurrent.focusPoint.y)
            }

            console.log("next kf:", {
                pctDone: pctTransition, 
                diffNext: ((this._keyframeNext.time - msTime)),
                zoom: viewModel.zoomLevel,
                fp: viewModel.focusPoint
            });
            
        } else {
            const zoomChanged = (viewModel.zoomLevel !== this._keyframeCurrent.zoomLevel);
            //just set the values from current
            viewModel.zoomLevel = this._keyframeCurrent.zoomLevel;
            viewModel.focusPoint = {
                x: this._keyframeCurrent.focusPoint.x,
                y: this._keyframeCurrent.focusPoint.y
            }

            if (zoomChanged) {
                console.log("prev kf:", {
                    zoom: viewModel.zoomLevel,
                    fp: viewModel.focusPoint
                });
            }
        }
        
    }

}

export default Timeline;