import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery, Stack } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import { HeaderProvider } from 'layout/HeaderContext';
import MobileNavbar from "components/Navbar/MobileNavbar";

import { hero } from "../../hero";

// types
import { openDrawer } from 'store/reducers/menu';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();

    const { drawerOpen } = useSelector((state) => state.menu);

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen]);

    return (<>
        {!hero.isMobile && (
            <HeaderProvider>
                <Box sx={{
                    display: 'flex',
                    width: '100%' 
                    }}>
                    <Header open={open} handleDrawerToggle={handleDrawerToggle} />
                    <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                        <Toolbar />
                        {/**<Breadcrumbs navigation={navigation} title />**/}
                        <Outlet />
                    </Box>
                </Box>
            </HeaderProvider>
        )}
        {hero.isMobile && (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh'
            }}>
                <Box component="main" sx={{
                    width: '100%',
                    flexGrow: 1,
                    overflowY: 'auto'
                }}>
                    <Toolbar />
                    {/**<Breadcrumbs navigation={navigation} title />**/}
                    <Outlet />
                </Box>
                <Box sx={{
                    height: '200px', // Fixed height for navbar
                    borderTop: "1px solid #333",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <MobileNavbar />
                </Box>
            </Box>
        )}
        </>);
};

export default MainLayout;
