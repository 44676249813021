class Team  {

    constructor(teamDoc, teamDocSet) {
        this._teamDoc = teamDoc;
        this._teamDocSet = teamDocSet;
        this._teamUpdates = {};
    }

    get id() {
        return this._teamDoc.id;
    }

    get name() {
        return this._teamDoc.data.name;
    }

    get birthYear() {
        return 2010;
    }

    get ageGroup() {
        //should be based on birth year
        return "U13";
    }

    /**
     * Micro >>>
     * Boost: Offer to buy ice cream the next time the team wins.
     * 
     * Seasonal >>>
     * Thank-you fund for coach.
     * Boost training with new equipment.
     * 
     * Annual >>>
     * Jersey Supporter - pays for uniforms.
     * Jersey Sponsor - add a patch to be applied.
     * 
     */
    getSponsorshipOpportunities() {
        return [];
    }

    getSponsorships() {
        return [];
    }

}

export default Team;