import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack, Chip } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import { Box } from '@mui/material';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open, close }) => {
    const theme = useTheme();

    return (
        // only available in paid version
        <DrawerHeaderStyled theme={theme} open={open}>
            <Stack direction="row" spacing={1} alignItems="center">
                <Box sx={{
                    borderRadius: "8px",
                    padding: "4px 12px",
                    display: "inline-block",
                    backgroundColor: "rgba(255,255,255, 0.5)",
                    display: "flex",
                    minWidth: "152px",
                    flexWrap: "nowrap",
                    cursor: "pointer"
                }} onClick={close}>
                    <img src="/static/logo-hero.webp" alt="Grassroots Hero" style={{
                        width: "100px"
                    }} />
                    <Box sx={{
                        width: "60px",
                        padding: "4px 0px 0px 12px"
                    }}>
                        <MenuFoldOutlined />
                    </Box>
                </Box>
            </Stack>
        </DrawerHeaderStyled>
    );
};

DrawerHeader.propTypes = {
    open: PropTypes.bool
};

export default DrawerHeader;
