import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery, Box } from '@mui/material';
import Logo from 'components/Logo';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const iconBackColor = 'grey.100';
    const iconBackColorOpen = 'grey.200';

    let toolbarSX = {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        boxSizing: "border-box",

    };
    if (matchDownXs) {
        toolbarSX.borderRadius = "8px";
        toolbarSX.backgroundColor = "rgba(255,255,255, 0.5)";
    }

    // common header
    const mainHeader = (
        <Toolbar>
            <Box sx={toolbarSX}>
                <Box sx={{
                    borderRadius: "8px",
                    padding: "8px 12px 0px 12px",
                    display: "inline-block",
                    backgroundColor: matchDownXs ? "transparent" : "rgba(255,255,255, 0.5)",
                    display: "flex",
                    minWidth: "152px",
                    opacity: open ? "0" : "1",
                    transition: 'opacity 0.5s',
                    flexWrap: "nowrap",
                    cursor: "pointer"
                }} onClick={handleDrawerToggle}>
                    <img src="/static/logo-hero.webp" alt="Grassroots Hero" style={{
                        width: "100px",
                        height: "33px"
                    }} />
                    <Box sx={{
                        width: "60px",
                        padding: "4px 0px 0px 12px"
                    }}>
                        {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Box>
                </Box>
                <HeaderContent />
            </Box>
        </Toolbar>
    );

    // app-bar params
    const appBar = {
        position: 'fixed',
        color: 'transparent',
        elevation: 0,
        sx: {
            //borderBottom: `1px solid ${theme.palette.divider}`
            // boxShadow: theme.customShadows.z1
        }
    };

    return (
        <>
            {!matchDownMD ? (
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    );
};

Header.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
};

export default Header;
