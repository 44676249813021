
import VideoFrameCache from "./VideoFrameCache";
import RenderedFrameCache from "./RenderedFrameCache";

/**
 * 
 * [birth-year][team-name][season][opponent][date]
 * 
 */

export default class GameVideoViewModel  {

    constructor(doc) {
        this.FPS = 30;
        this.frameCache = new VideoFrameCache(this.FPS);
        this.renderedCache = new RenderedFrameCache(this.FPS);

        this.zoomLevel = 1.0;
        this.targetResolution = { width: 1280, height: 720 };
        
        //playhead -> set by the video callbacks and == the current frame on the video
        this.playhead = 0.0;
        
        //playheadTarget -> set by the scrubber, represents the timeline state and Canvas state
        this.playheadTarget = 0.0;

        //used to set the destination frame on the video
        this.videoheadTarget = 0.0;

        //playhead
        this.playheadIsSeeking = false;

        this.playSpeed = 0.0;

        //time-scale for the main timeline, controlled by TimelineGlobal
        this.startRange = 0.0;
        this.endRange = 0.0;

        //ditch corner & center for focusPoint
        this.cornerPosition = { x: 0, y: 0 };
        this.centerPosition = { x: 640, y: 360 };

        this.focusPoint = { x: 0.5, y: 0.5 };

        this.gameMeta = {
            currentHalf: 1,
            startHalf: 0,
            endHalf: 45,
            isPeriodOver: false,
            halfLength: 45,
            
            home: {
                moniker: "HME",
                color: "#336699",
                contrast: "#FFFFFF",
                score: 0
            },
            away: {
                moniker: "AWY",
                color: "#222222",
                contrast: "#FFFFFF",
                score: 0
            }
        };

        //includes .duration
        this.videoMeta = {};

        this.stats = {};
        this._listeners = {};

        this._currentSpotlight = null;
    }

    get currentSpotlight() {
        return this._currentSpotlight;
    }

    set currentSpotlight(spot) {
        this._currentSpotlight = spot;
    }

    setVideoMeta(vid) {
        this.videoMeta.width = vid.videoWidth;
        this.videoMeta.height = vid.videoHeight;

        this.videoMeta.duration = vid.duration;
        this.endRange = this.videoMeta.duration;
        this.fireEvent("seekVideo");
    }

    addEventListener(eventName, cbFunc) {
        this._listeners[eventName] = this._listeners[eventName] || [];
        this._listeners[eventName].push(cbFunc);
    }

    removeEventListener(eventName, cbFunc) {
        this._listeners[eventName].splice(this._listeners[eventName].indexOf(cbFunc), 1);
    }

    fireEvent(eventName, payload) {
        if (this._listeners[eventName]) {
            for (let i=0;i<this._listeners[eventName].length;i++) {
                this._listeners[eventName][i](payload);
            }
        }
    }

    snapshot() {
        this._snapshot = {
            playhead: this.playhead,
            cornerPosition: { x: this.cornerPosition.x, y: this.cornerPosition.y },
            centerPosition: { x: this.centerPosition.x, y: this.centerPosition.y },
            focusPoint: { x: this.focusPoint.x, y: this.focusPoint.y },
            zoomLevel: this.zoomLevel,
            targetResolution: { x: this.targetResolution.x, y: this.targetResolution.y },
            playSpeed: this.playSpeed
        };
    }

    hasChanged() {

        const ret = (!this._snapshot ||
            this.playhead !== this._snapshot.playhead ||

            this.cornerPosition.x !== this._snapshot.cornerPosition.x ||
            this.cornerPosition.y !== this._snapshot.cornerPosition.y ||
            this.centerPosition.x !== this._snapshot.centerPosition.x ||
            this.centerPosition.y !== this._snapshot.centerPosition.y ||

            this.focusPoint.x !== this._snapshot.focusPoint.x ||
            this.focusPoint.y !== this._snapshot.focusPoint.y ||

            this.zoomLevel !== this._snapshot.zoomLevel ||
            this.targetResolution.x !== this._snapshot.targetResolution.x ||
            this.targetResolution.y !== this._snapshot.targetResolution.y ||
            this.playSpeed !== this._snapshot.playSpeed);
        if (ret) {
            console.log("haschanged:", this);
        }
        return ret;
    }


    clampFocus() {
        const videoWidth = this.videoMeta.width;
        const videoHeight = this.videoMeta.height;
        const targW = this.targetResolution.width * this.zoomLevel;
        const targH = this.targetResolution.height * this.zoomLevel;

        //position the overlay
        const maxFocusX = (videoWidth - (targW / 2.0))/videoWidth;
        const minFocusX = (targW / 2.0)/videoWidth;
        const maxFocusY = (videoHeight - (targH / 2.0))/videoHeight;
        const minFocusY = (targH / 2.0)/videoHeight;

        this.focusPoint.x = Math.min(Math.max(minFocusX, this.focusPoint.x), maxFocusX);
        this.focusPoint.y = Math.min(Math.max(minFocusY, this.focusPoint.y), maxFocusY);
    }

    compareTimes(timeA, timeB) {
        let timeARound = Math.round(1000 * timeA)/1000.0;
        let timeBRound = Math.round(1000 * timeB)/1000.0;
        return (timeARound === timeBRound);
    }

    isVideoheadAt(timeIn) {        
        return this.compareTimes(timeIn, this.playhead);
    }

    getFrameLength() {
        return (1.0 / this.FPS);
    }

}