
import { useRef, useState } from 'react';

import { hero } from "hero";
import { useSelector } from 'react-redux';

import { dispatch } from "store";
import { onThemeModeUpdate } from "store/reducers/appShell";

import { Link } from "react-router-dom"

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, Avatar, Box, Button, ButtonBase, CardContent, ClickAwayListener, Grid, IconButton, Paper, Popper, Stack, Typography } from '@mui/material';

import Loader from 'components/Loader';
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

// assets
import { LogoutOutlined, UserOutlined, WalletOutlined, QuestionCircleOutlined, BgColorsOutlined } from '@ant-design/icons';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const appState = useSelector((state) => {
        return {
            loggedIn: state.appShell.loggedIn,
            isInitialized: state.appShell.isInitialized,
            themeMode: state.appShell.themeMode
        };
    });

    const handleThemeModeChange = (event, index) => {
        let targetMode = "dark";
        if (appState.themeMode === "dark") {
            targetMode = "light";
        }
        dispatch(onThemeModeUpdate(targetMode));
    };

    const handleLogout = async () => {
        // logout
        console.warn("LOG OUT B");
    };

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const iconBackColorOpen = 'grey.300';

    if (!appState.isInitialized) {
        return (<Loader></Loader>);
    }

    if (!appState.loggedIn) {
        return (<Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <Button>Sign Up</Button><Button>Sign In</Button>
        </Box>);
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <ButtonBase
                sx={{
                    borderRadius: "8px",
                    padding: "4px 12px",
                    backgroundColor: !matchesXs ? "rgba(255,255,255, 0.5)" : "transparent",
                    "&:focus": {
                        outline: "none"
                    }
                }}
                aria-label="Open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                    <Avatar alt="profile user" src={hero.user.photoURL} sx={{ width: 32, height: 32 }} />
                    {!matchesXs && <Typography variant="subtitle1">{hero.user.name}</Typography>}
                </Stack>
            </ButtonBase>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        {open && (
                            <Paper
                                sx={{
                                    boxShadow: theme.customShadows.z1,
                                    width: 290,
                                    minWidth: 240,
                                    maxWidth: 290,
                                    [theme.breakpoints.down('md')]: {
                                        maxWidth: 250
                                    }
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MainCard elevation={0} border={false} content={false}>
                                        <CardContent sx={{ px: 2.5, pt: 3 }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Stack direction="row" spacing={1.25} alignItems="center">
                                                        <Avatar alt="profile user" src={hero.user.photoURL} sx={{ width: 32, height: 32 }} />
                                                        <Stack>
                                                            <Typography variant="h6">{hero.user.name}</Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                                ROLE-TBD
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>OPP</Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
                                            <ListItem selected={selectedIndex === 4}>
                                                {(appState.themeMode === "light") ? (<>
                                                    <ListItemIcon>
                                                        <BgColorsOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Dark Mode" />
                                                    <IconButton sx={{ ml: 1 }} onClick={handleThemeModeChange} color="inherit">
                                                        <Brightness7Icon />
                                                    </IconButton>
                                                </>) : (<>
                                                    <ListItemIcon>
                                                        <BgColorsOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Light Mode" />
                                                    <IconButton sx={{ ml: 1 }} onClick={handleThemeModeChange} color="inherit">
                                                        <Brightness4Icon />
                                                    </IconButton>
                                                </>)}
                                            </ListItem>
                                            <ListItemButton selected={selectedIndex === 1} component={Link} to="/profile" onClick={(event) => handleListItemClick(event, 1)}>
                                                <ListItemIcon>
                                                    <UserOutlined />
                                                </ListItemIcon>
                                                <ListItemText primary="Profile" />
                                            </ListItemButton>
                                            <ListItemButton selected={selectedIndex === 4} component={Link} to="/account" onClick={(event) => handleListItemClick(event, 4)}>
                                                <ListItemIcon>
                                                    <WalletOutlined />
                                                </ListItemIcon>
                                                <ListItemText primary="Account" />
                                            </ListItemButton>
                                            <ListItemButton selected={selectedIndex === 0} component={Link} to="/support" onClick={(event) => handleListItemClick(event, 0)}>
                                                <ListItemIcon>
                                                    <QuestionCircleOutlined />
                                                </ListItemIcon>
                                                <ListItemText primary="Support" />
                                            </ListItemButton>
                                            <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
                                                <ListItemIcon>
                                                    <LogoutOutlined />
                                                </ListItemIcon>
                                                <ListItemText primary="Logout" />
                                            </ListItemButton>
                                        </List>
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default Profile;
