import { React, useState } from 'react';

// material-ui
import { Button, CardMedia, Link, Stack, Typography } from '@mui/material';

import { hero } from 'hero';
import FeedbackForm from 'components/FeedbackForm';


// project import
import MainCard from 'components/MainCard';

// ==============================|| DRAWER CONTENT - NAVIGATION CARD ||============================== //

const NavCard = () => {

    const [showFeedbackForm, setShowFeedbackForm] = useState(false);

    const handleFeedbackClick = () => {
        setShowFeedbackForm(true);
    };

    const handleFeedbackComplete = () => {
        setShowFeedbackForm(false);
    };

    return (<>
    <MainCard sx={{ bgcolor: 'grey.50', m: 3 }}>
        <Stack alignItems="center" spacing={2.5}>
            <CardMedia component="img" image="/static/logo.png" sx={{ width: 112 }} />
            <Stack alignItems="center">
                <Typography variant="h5">Help us Improve</Typography>
                <Typography variant="h6" color="secondary">
                    Share your thoughts!
                </Typography>
            </Stack>
            
            <Button component={Link} target="_blank" variant="contained" color="success" size="small" onClick={handleFeedbackClick}>
                Feedback...
            </Button>
        </Stack>
    </MainCard>
    {showFeedbackForm && (<FeedbackForm onComplete={handleFeedbackComplete} />)}
    </>);
};

export default NavCard;
