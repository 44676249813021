// project import
import coaches from './coaches';
import managers from './managers';
import clubhouse from './clubhouse';
import { hasFeature } from "features";
import { PartitionOutlined } from '@ant-design/icons';

// ==============================|| MENU ITEMS ||============================== //


const menuItems = {
    items: [{
        id: 'home',
        title: '',
        type: 'group',
        authOnly: true,
        children: [
        {
            id: 'home1',
            title: 'Home',
            type: 'item',
            url: '/home',
            icon: PartitionOutlined,
            target: false
        }]
    }]
};
if (hasFeature("clubhouse")) {
    menuItems.items.push(clubhouse);
}
if (hasFeature("managers_desk")) {
    menuItems.items.push(managers);
}
if (hasFeature("coaches_corner")) {
    menuItems.items.push(coaches);
}

export default menuItems;
