// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    isInitialized: false,
    loggedIn: false,
    themeMode: 'light'
};

// ==============================|| SLICE - APP ||============================== //

const appShell = createSlice({
    name: 'hero',
    initialState,
    reducers: {
        onAuthUpdate(state, action) {
            state.isInitialized = true;
            state.loggedIn = action.payload.loggedIn;
        },

        onThemeModeUpdate(state, action) {
            state.themeMode = action.payload;
        }
    }
});

export default appShell.reducer;

export const { onAuthUpdate, onThemeModeUpdate } = appShell.actions;
