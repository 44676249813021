
class Session  {

    constructor(sessionDoc) {
        this._sessionDoc = sessionDoc;
        this._sessionUpdates = {};
    }

    get id() {
        if (this._sessionDoc) {
            return this._sessionDoc.id;
        }
        return "new";
    }
    

}

export default Session;