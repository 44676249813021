// material-ui
import { Box, IconButton, Link, useMediaQuery } from '@mui/material';
import { hasFeature } from "features";

// project import
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';
import { useHeader } from 'layout/HeaderContext';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    
    const { headerContent } = useHeader();

    return (
        <>
            <Box sx={{ 
                width: '100%',
                ml: { xs: 0, md: 1 } }}>
                {headerContent}
            </Box>

            {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
            {hasFeature("notifications") ? (<Notification />) : (null)}
            <Profile />
        </>
    );
};

export default HeaderContent;
